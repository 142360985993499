import { computed, reactive } from 'vue'
import { differenceInMinutes } from 'date-fns'
import { DeviationSummary, DeviationSummaryPeriod } from '@dview/shared/models'
import { sleep } from '@dview/shared/util'
import { useBackend } from './use-backend'

// singleton state instance for entire app
const state = reactive<DeviationSummaryState>({
    status: 'loading',
    summary: null!,
    period: '24hours',
    lastUpdated: new Date()
})

/**
 * Represents a summary across all types of deviations.
 * 
 * @returns 
 */
export function useDeviationSummary() {
    const { deviationsService } = useBackend()

    async function load(period: DeviationSummaryPeriod) {
        try {
            state.status = 'loading'
            state.period = period

            await sleep(500)
            state.summary = await deviationsService.getDeviationSummary(period)

            state.lastUpdated = new Date()
            state.status = 'loaded'
        } catch (e) {
            console.error(e)
            state.status = 'error'
        }
    }

    /**
     * Refreshes the deviation data, if sufficient time has passed, given last used filter.
     */
    function refresh() {
        if (state.status === 'loading') {
            return
        }

        if (summaryNeedsUpdating()) {
            load(state.period)
        }
    }

    /**
     * Returns true if 15 minutes have passed since deviation summary was last fetched from backend.
     */
     function summaryNeedsUpdating() {
        return differenceInMinutes(new Date(), state.lastUpdated) > 15
    }

    return {
        status: computed(() => state.status),
        summary: computed(() => state.summary),
        period: computed(() => state.period),
        load,
        refresh
    }
}

interface DeviationSummaryState {
    summary: DeviationSummary
    status: DeviationSummaryStatus
    period: DeviationSummaryPeriod
    lastUpdated: Date
}

export type DeviationSummaryStatus = 'loading' | 'loaded' | 'error'