import { Ref, ref, computed } from 'vue'
import {
    AuthenticationResult,
    PublicClientApplication,
    Configuration,
    AccountInfo,
    RedirectRequest,
    PopupRequest,
    LogLevel,
} from '@azure/msal-browser'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { appConfig } from '@dview/core/appconfig'
import { getTokenExpiryTime } from '@dview/shared/util/jwt'
import { AuthenticatedUser, DViewAuthentication, LoginStatus, RefreshSessionStatus } from './auth.interface'

interface AuthenticationState {
    msalInstance: MSAL
    inited: boolean
    user: Ref<AuthenticatedUser>
}

const state: AuthenticationState = {
    msalInstance: null!,
    inited: false,
    user: ref<AuthenticatedUser>(null!)
}

export function useAuthWeb(): DViewAuthentication {

    if (!state.inited) {
        init()
    }

    function init() {
        initializeMsalLibrary()
    }

    function login() {
        if (isReturnedFromRedirectContext()) {
            return state.msalInstance.loadAuthModule()
        } else {
            return state.msalInstance.login()
        }
    }

    // TODO: TO BE IMPLEMENTED
    function logout() {
        return Promise.resolve()
    }

    function refreshSession() {
        return state.msalInstance.loadAuthModule()
            .then(status => {
                if (status === LoginStatus.Success) {
                    return RefreshSessionStatus.Success   
                } else {
                    return RefreshSessionStatus.Expired
                }
            })
            .catch(err => {
                return RefreshSessionStatus.Expired
            })
    }

    function isReturnedFromRedirectContext() {
        return !!window.location.hash
    }

    function getAccessToken() {
        return state.msalInstance?.data?.custom?.backendToken
    }

    function isAccessTokenAvailable() {
        return state.msalInstance.data.isAuthenticated && state.msalInstance.data.custom.backendToken != null
    }

    function isAccessTokenExpired() {
        const expiryTime = state.msalInstance.data.accessTokenExpiryTime
        
        if (expiryTime == null) {
            return true
        }

        return differenceInMinutes(expiryTime, new Date()) <= 5 // treat expiry as 5 minutes before actual expiry time
    }

    function isAuthenticated() {
        return state.msalInstance.data.isAuthenticated && !isAccessTokenExpired()
    }

    return {
        getAccessToken,
        user: computed(() => state.user.value),
        isAccessTokenAvailable,
        isAccessTokenExpired,
        isAuthenticated,
        login,
        logout,
        refreshSession
    }
}

function initializeMsalLibrary() {
    state.inited = true
    state.msalInstance = new MSAL()
    state.msalInstance.init({
        auth: {
            authority: 'https://login.microsoftonline.com/fdfed7bd-9f6a-44a1-b694-6e39c468c150/',
            requireAuthOnInitialize: true,
            clientId: appConfig.msalClientId,
            backendScopes: [appConfig.msalBackendScope],
            redirectUri: window.location.protocol + '//' + window.location.host + '/login',
            navigateToLoginRequestUrl: false,
        },
        cache: {
            cacheLocation: 'sessionStorage',
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message)
                            return
                        case LogLevel.Info:
                            //console.info(message)
                            return
                        case LogLevel.Verbose:
                            //console.debug(message)
                            return
                        case LogLevel.Warning:
                            console.warn(message)
                            return
                    }
                },
            },
        },
    })
}

/**
 * This class is responsible for handling everything related to AD login, using the MSAL.js library
 */
 export class MSAL {
    public data: MSALDataObject = {
        isAuthenticated: false,
        accessToken: '',
        accessTokenExpiryTime: null!,
        idToken: '',
        user: null,
        graph: {},
        custom: { backendToken: '' },
    }

    private lib: PublicClientApplication = null!
    private account: AccountInfo | undefined | null
    private options: Options | undefined

    constructor() {}

    public init(options: Options) {
        this.options = options
        if (!options.auth || !options.auth.clientId) {
            console.warn('client id missing. Cannot setup authentication')
            return
        }

        this.lib = new PublicClientApplication({
            ...options,
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: false,
                ...options.cache,
            },
        })
    }

    /**
     * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
     *
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
     */
    loadAuthModule(): Promise<LoginStatus> {
        return this.lib.handleRedirectPromise()
            .then(resp => this.handleResponse(resp))
            .then(status => {
                return status === RefreshSessionStatus.Success ? LoginStatus.Success : LoginStatus.Failed
            })
            .catch(err => {
                this.data.isAuthenticated = false
                this.data.user = null
                console.error('error from handleRedirectPromise', err)
                return LoginStatus.Failed
            })
    }

    /**
     * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
     * @param response
     */
    async handleResponse(response: AuthenticationResult | null) {
        if (response !== null) {
            this.setUserInfo(response.account)
            this.data.accessToken = response.accessToken
            return this.getBackendToken()
        } else {
            this.setUserInfo(this.getAccount())
            return this.getBackendToken()
        }
    }

    setUserInfo(account: AccountInfo | null) {
        this.account = account
        this.data.user = this.account
        this.data.isAuthenticated = true
        state.user.value = {
            name: account?.name ?? 'Unknown',
            username: account?.username ?? ''
        }
    }

    async getBackendToken() {
        const account = this.getAccount()
        if (account && this.options) {
            try {
                const backendTokenResponse = await this.lib?.acquireTokenSilent({
                    account,
                    scopes: this.options.auth.backendScopes,
                })
                this.data.custom.backendToken = backendTokenResponse?.accessToken
                this.data.accessTokenExpiryTime = getTokenExpiryTime(backendTokenResponse?.accessToken)

                return RefreshSessionStatus.Success
            }
            catch (e) {
                console.error('Error on acquiring token silently.')
                return RefreshSessionStatus.Expired
            }
        }
        return RefreshSessionStatus.Expired
    }

        /**
     * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
     * TODO: Add account chooser code
     *
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    private getAccount(): AccountInfo | null {
        // need to call getAccount here?
        const currentAccounts = this.lib?.getAllAccounts()
        if (!currentAccounts) {
            console.log('No accounts detected')
            return null
        }

        if (currentAccounts.length > 1) {
            // Add choose account code here
            console.log('Multiple accounts detected, need to add choose account code.')
            return currentAccounts[0]
        } else if (currentAccounts.length === 1) {
            return currentAccounts[0]
        }
        return null
    }

    /**
     * Calls loginPopup or loginRedirect based on given signInType.
     * @param signInType
     */
    login() {
        const loginRequest: RedirectRequest | PopupRequest = {
            scopes: ['User.Read'],
        }

        return this.lib.loginRedirect(loginRequest)
            .then(() => LoginStatus.Success)
            .catch(err => {
                console.error('error from loginRedirect', err)
                return LoginStatus.Failed
            })
    }
}

type Options = Configuration & {
    auth: { requireAuthOnInitialize: boolean; backendScopes: string[] }
}

export type MSALDataObject = {
    isAuthenticated: boolean
    accessToken: string
    accessTokenExpiryTime: Date
    idToken: string
    user: AccountInfo | null
    graph: object
    custom: any
}