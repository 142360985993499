import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useUserPreferences } from '@dview/logic'

export function OnboardedGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const { preferences } = useUserPreferences()

    if (preferences.userOnboarded) {
        next()
    } else {
        next({ name: 'Onboarding' })
    }
}