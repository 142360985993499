import { Ref, ref } from 'vue'
import { appConfig } from '@dview/core'
import { ApplicationInsights, IEventTelemetry, IExceptionTelemetry, IMetricTelemetry, IPageViewTelemetry, ITelemetryItem } from '@microsoft/applicationinsights-web'
import { OrganizationUnit } from '@dview/shared/models'
import { OrganizationAreasState, useAuth, useNative } from '@dview/logic'
import { useDViewGlobalRouter } from '@dview/core'

const appInsights = appConfig.azureAppInsightsKey ? new ApplicationInsights({
    config: {
        instrumentationKey: appConfig.azureAppInsightsKey,
        loggingLevelConsole: 2,
        loggingLevelTelemetry: 2,
        enableDebugExceptions: true,
        autoTrackPageVisitTime: true
    }
}) : null

const timeElapsedOnPage: Ref<number> = ref(0)
let inited = false

/* Link Vue app to Microsoft Azure's Application Insights monitoring tool */
export function useAppInsights() {
    const { onAppPause, onAppResume } = useNative()

    if (!inited) {
        onAppPause(trackTimeSpentBeforeMinimised)
        onAppResume(resetTimerForTrackingElapsed)
        inited = true
    }

    return {
        initAppInsights: () => appInsights?.loadAppInsights(),
        trackPageView: (pageView?: IPageViewTelemetry) => appInsights?.trackPageView(pageView),
        addTelemetryInitializer: (telemetryInitializer: (item: ITelemetryItem) => boolean | void) => appInsights?.addTelemetryInitializer(telemetryInitializer),
        trackEvent: (event: IEventTelemetry) => trackEventOfAuthenticatedUser(event),
        trackMetric: (metric: IMetricTelemetry) => appInsights?.trackMetric(metric),
        trackException: (exception: IExceptionTelemetry) => appInsights?.trackException(exception),
        timeElapsedOnPage: timeElapsedOnPage,
        trackTimeElapsedOnPage: (trackedPage: string) => trackTimeElapsedOnPage(trackedPage),
        shouldPageBeTracked: (currentPage: string) => shouldPageBeTracked(currentPage)
    }
}

function resetTimerForTrackingElapsed() {
    const { timeElapsedOnPage } = useAppInsights()

    timeElapsedOnPage.value = Date.now()
}

function trackTimeSpentBeforeMinimised() {
    const { timeElapsedOnPage, trackEvent, trackTimeElapsedOnPage, trackMetric } = useAppInsights()
    const { getCurrentRoute } = useDViewGlobalRouter()
    const trackedPageBeforeMinimised = getCurrentRoute()

    if (timeElapsedOnPage.value > 0 && shouldPageBeTracked(trackedPageBeforeMinimised)) {
        const { formattedPageName, formattedTimeElapsed, deltaTime } = trackTimeElapsedOnPage(trackedPageBeforeMinimised)

        trackEvent({
            name: 'Elapsed time in page',
            properties: {
                'app-paused': true,
                [`elapsed-${formattedPageName}`]: formattedTimeElapsed,
                'elapsed-comparable': deltaTime,
                'page': trackedPageBeforeMinimised
            }
        })

        trackMetric({ name: `ElapsedTime_${trackedPageBeforeMinimised}`, average: deltaTime})
    }
}

function trackTimeElapsedOnPage(trackedPage: string) {
    const deltaTime = (Date.now() - timeElapsedOnPage.value) / 1000

    const formattedTimeElapsed = deltaTime > 60 ? 
        `${Math.floor(deltaTime / 60)} min, ${parseFloat(String(deltaTime % 60)).toFixed(2)} sec` :
        `${parseFloat(String(deltaTime)).toFixed(2)} sec`

    // replaces spaces with hyphens and lower-cases the string
    const formattedPageName = trackedPage.replace(/\s+/g, '-').toLowerCase()

    return {
        formattedPageName,
        formattedTimeElapsed,
        deltaTime
    }
}

function trackEventOfAuthenticatedUser(event: IEventTelemetry) {
    const { user } = useAuth()

    const loggedEvent = {
        name: event.name,
        properties: Object.assign({
            'user-initials': user.value?.username ?? ''
        }, event.properties)
    }

    appInsights?.trackEvent(loggedEvent)
}

function shouldPageBeTracked(trackedPage: string): boolean {
    return trackedPage === 'DeviationsOpen' || trackedPage === 'DeviationsHocRatings'
}

/* Format and stringify Selected Organization Filters */
export function selectedFiltersToString(filters: OrganizationAreasState): string {
    const selectedFilters: SelectedFiltersKeys = {}
    if (filters.svp.selected.length) {
        selectedFilters.svp = filters.svp.selected.map((selectedFilter: OrganizationUnit) => selectedFilter.name)
    }
    if (filters.cvp.selected.length) {
        selectedFilters.cvp = filters.cvp.selected.map((selectedFilter: OrganizationUnit) => selectedFilter.name)
    }
    if (filters.vp.selected.length) {
        selectedFilters.vp = filters.vp.selected.map((selectedFilter: OrganizationUnit) => selectedFilter.name)
    }
    if (filters.department.selected.length) {
        selectedFilters.department = filters.department.selected.map((selectedFilter: OrganizationUnit) => selectedFilter.name)
    }
    if (filters.team.selected.length) {
        selectedFilters.team = filters.team.selected.map((selectedFilter: OrganizationUnit) => selectedFilter.name)
    }
    return JSON.stringify(selectedFilters)
}

interface SelectedFiltersKeys {
    svp?: string[]
    cvp?: string[]
    vp?: string[]
    department?: string[]
    team?: string[]
}
