import { readonly } from 'vue'
import { OrganizationUnit, NotificationMode, UserPreferences, NotificationSettings } from '@dview/shared/models'
import { useBackend } from './use-backend'
import { usePromiseQueue } from '@dview/shared/util'

const state: UserPreferences = {
    userId: '',
    userOnboarded: false,
    notifications: {
        mode: NotificationMode.None,
        newDeviations: false,
        newHocRatings: false
    },
    orgFilter: []
}

// Queue up calls to update preferences on the backend, to prevent race conditions in cosmos db.
// This can happen since we, from the frontend, only sent part preference updates to the backend,
// and sometimes multiple updates at a time.
const { enqueue: saveUserPreference } = usePromiseQueue()

export function useUserPreferences() {
    const { userPreferencesService } = useBackend()

    async function load() {
        const savedPreferences = await userPreferencesService.getUserPreferences()
        Object.assign(state, savedPreferences)
    }

    function setUserOnboarded(onboarded: boolean) {
        state.userOnboarded = onboarded
    }

    function setOrganizationFilter(filter: OrganizationUnit[]) {
        return saveUserPreference(async () => {
            await userPreferencesService.updateOrganizationFilter(filter)
            state.orgFilter = filter
        })
    }

    function setNotificationSettings(notificationSettings: NotificationSettings) {
        return saveUserPreference(async () => {
            await userPreferencesService.updateNotificationSettings(notificationSettings)
            state.notifications = notificationSettings
        })
    }

    return {
        preferences: readonly(state),
        setUserOnboarded,
        setOrganizationFilter,
        setNotificationSettings,
        load,
    }
}
