import { AxiosError } from 'axios'

// TODO: make this more explicit when we get clearer on how backend sends error messages back
// TODO: and perhaps introduce a parent DViewError class to extend
export class DViewBackendError extends Error {
    constructor(message?: string, public errorData?: any) {
        super(message)
        this.name = 'DViewBackendError'
        Object.setPrototypeOf(this, new.target.prototype)
    }
}

/**
 * @see https://github.com/axios/axios#handling-errors
 */
export function handleBackendError(error: AxiosError): never {
    if (error.isAxiosError) {
        console.log(error.toJSON())
        if (error.response && error.response.data) {
            // expect the API to handle error responses in valid format
            throw new DViewBackendError(error.message, error.response.data)
        } else if (error.request) {
            // handle the default error response for Network failure or 404 etc.,
            throw new DViewBackendError(error.request.message || error.request.statusText)
        }
    }
    throw error
}