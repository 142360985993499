import { appConfig } from '@dview/core/appconfig'
import { ref } from 'vue'
import { AuthenticatedUser, DViewAuthentication, LoginStatus, RefreshSessionStatus } from './auth.interface'

// When building DView in sandbox (mocked) mode, it is also possible to go through
// a "mocked" login flow, where the app doesn't skip the login flow entirely, but 
// behaves similarly to when running the app with normal data.
//
// Generally not neccessary for most sandbox testing use cases, but could be handy
// in situations where you need to replicate the boot flow more 1:1 like real data.
let authenticated = appConfig.mock.login ? false : true

const mockUser = ref<AuthenticatedUser>({
    name: 'DView user',
    username: 'NNXX',
})

export function useAuthMock(): DViewAuthentication {

    function login() {
        authenticated = true
        return Promise.resolve(LoginStatus.Success)
    }

    return {
        getAccessToken: () => '',
        user: mockUser,
        isAccessTokenAvailable: () => authenticated === true,
        isAccessTokenExpired: () => authenticated === false,
        isAuthenticated: () => authenticated,
        login,
        logout: () => Promise.resolve(),
        refreshSession: () => Promise.resolve(authenticated ? RefreshSessionStatus.Success : RefreshSessionStatus.Expired)
    }
}