import { computed, ref } from 'vue'
import { App } from '@capacitor/app'
import { AccountInfo, LogLevel, PublicClientApplication } from '@azure/msal-browser'
import { differenceInHours, differenceInMinutes } from 'date-fns'
import { appConfig, useDViewGlobalRouter } from '@dview/core'
import { AuthenticatedUser, DViewAuthentication, LoginStatus, RefreshSessionStatus } from './auth.interface'
import { useNative } from '../use-native'
import { getTokenExpiryTime } from '@dview/shared/util/jwt'

const state = {
    msal: null! as PublicClientApplication,
    inited: false,
    loginRedirectInProgress: false,
    lastLoginTimestamp: null! as Date,
    accessToken: null! as string,
    accessTokenExpiryTime: null! as Date,
    userAccount: null! as AccountInfo,
    user: ref<AuthenticatedUser>(null!)
}

export function useAuthNative(): DViewAuthentication {

    if (!state.inited) {
        init()
    }

    function init() {
        state.inited = true
        attachNativeUniversalLinkListener()
        addSessionRefreshListener()
    }

    /**
     * Adds a listener to the app resume event, meaning whenever the user returns to a DView app instance
     * which was running in the background on his iOS device.
     * 
     * This is a good time to check if we should do a silent refresh of his token, if possible, and if not
     * redirect him to login page to reauthorize.
     */
    function addSessionRefreshListener() {
        const { onAppResume } = useNative()
        
        // idea is to try to refresh access token every time user re-focuses the app, but to avoid doing it 
        // too often, in case the user keeps opening and closing the app, as many re-authentication attempts
        // within a short span of time could potentially block the user in Azure for a short period.
        const APP_RESUME_TOKEN_REFRESH_DELAY = 5
        let lastAppResumeRefreshTime = new Date()
        
        const tokenRefreshRequired = () => !isAuthenticated() || differenceInMinutes(new Date(), lastAppResumeRefreshTime) > APP_RESUME_TOKEN_REFRESH_DELAY

        onAppResume(async () => {
            // if the app resume events occurs during an in progress authentication redirect, ignore it,
            // otherwise we would end up spawning a secondary login prompt.
            if (state.loginRedirectInProgress) {
                return
            }

            // if user's token has expired or the app resume delay condition is met, it is time to attempt to refresh the token
            if (tokenRefreshRequired()) {
                lastAppResumeRefreshTime = new Date()

                // don't try to refresh token if we have reached the login time limit, which seems to be set to 1 day.
                // reason is that trying to silently refresh a token once entire login credential is expired, seems to
                // result in unwanted iframe timeout, causing app to stall for a while before actually logging in anew.
                if (isLoginTimelimitExceeded()) {
                    const router = useDViewGlobalRouter()
                    router.replace({ name: 'Login' }) 
                    console.info('MSAL login timeout reached, starting new interactive login.')
                    return
                }

                const refreshStatus = await refreshSession()

                // if attempt to refresh token silently behind the scenes failed, redirect user to login page
                if (refreshStatus === RefreshSessionStatus.Expired) {
                    const router = useDViewGlobalRouter()
                    router.replace({ name: 'Login' })
                }
                else {
                    console.info('Silently refreshed token.')
                }
            }
        })
    }

    /**
     * Begins the native login flow using iOS universal links.
     * MSAL library will open an external Safari browser to complete the login flow, and upon successful SSO authenticaton,
     * redirect back to the app with a universal link notification event, containing the auth code neccessary to complete
     * the rest of the login flow.
     */
    async function login() {
        const msal = state.msal = initializeMsalNativeBrowserAuthentication() // recreate MSAL instance on every new login attempt

        state.loginRedirectInProgress = true // important for app pause / resume listener

        // Special case to wait for TestFlight "what to test" message to disappear, so login flow doesn't stall in
        // being unable to open Safari in a paused state.
        if (await isRunningViaAppleTestFlight()) {
            await waitForTestFlightMessageToDisappear()
        }
    
        // returns a promise, but docs say to basically ignore it
        msal.loginRedirect({ scopes: [ 'User.Read' ] })
            .catch(err => console.error('Error in MSAL loginRedirect', err)) // this catch should never happen

        // wait for universal link callback to be received by native app
        let authCodeFragment = await getAuthCodeFragmentFromNativeRedirect()
        
        // important to add '#' to the hash fragment containing the auth code, for MSAL to parse it correctly
        if (!authCodeFragment.startsWith('#')) { 
            authCodeFragment = '#' + authCodeFragment
        }

        // give the authcode to MSAL library for further processing, in order to get accesstoken
        const authResult = await msal.handleRedirectPromise(authCodeFragment)

        if (!authResult) {
            console.error('Login failed, auth result from MSAL was null')
            return LoginStatus.Failed
        }

        try {
            const userAccount = authResult.account!
            const tokenResponse = await msal.acquireTokenSilent({
                account: userAccount,
                scopes: [ appConfig.msalBackendScope ],
            })

            state.accessToken = tokenResponse.accessToken
            state.accessTokenExpiryTime = getTokenExpiryTime(tokenResponse.accessToken)
            
            setActiveUser(userAccount)

            state.loginRedirectInProgress = false // important for app pause / resume listener
            state.lastLoginTimestamp = new Date()

            return LoginStatus.Success
        }
        catch (e) {
            console.error('Error during MSAL login.', e)
            return LoginStatus.Failed
        }
    }

    async function refreshSession() {
        const { msal } = state

        try {
            const cachedUserAccount = state.userAccount ?? msal.getActiveAccount()

            if (cachedUserAccount == null) {
                return RefreshSessionStatus.Expired
            }

            const tokenResponse = await msal.acquireTokenSilent({
                account: cachedUserAccount,
                scopes: [ appConfig.msalBackendScope ],
            })

            state.accessToken = tokenResponse.accessToken
            state.accessTokenExpiryTime = getTokenExpiryTime(tokenResponse.accessToken)

            setActiveUser(cachedUserAccount)

            return RefreshSessionStatus.Success
        }
        catch (e) {
            console.error('Error during MSAL token refresh.', e)
            return RefreshSessionStatus.Expired
        }
    }

    /**
     * Sets the active logged in MSAL AccountInfo user object on state following a successful login.
     * 
     * @param account 
     */
    function setActiveUser(account: AccountInfo) {
        state.msal.setActiveAccount(account)
        state.userAccount = account
        state.user.value = {
            name: account?.name ?? 'Unknown',
            username: account?.username ?? ''
        }
    }

    /**
     * NOT IMPLEMENTED YET !
     */
    function logout() {
        // TO BE IMPLEMENTED
        return Promise.resolve()
    }

    /**
     * Returns the last acquired access token.
     * 
     * @returns 
     */
    function getAccessToken() {
        return state.accessToken
    }

    /**
     * Indicates if an access token is available.
     * 
     * @returns 
     */
    function isAccessTokenAvailable() {
        return state.accessToken != null
    }

    /**
     * Indicates if the user is currently successfully logged in.
     * 
     * @returns 
     */
    function isAuthenticated() {
        return !isLoginTimelimitExceeded() && isAccessTokenAvailable() && !isAccessTokenExpired()
    }

    /**
     * An MSAL accesstoken typically lasts for 60 minutes, but we treat it as if it only has a lifetime of
     * 55 minutes.
     * 
     * @returns 
     */
    function isAccessTokenExpired() {
        if (state.accessTokenExpiryTime == null) {
            return true
        }
        return differenceInMinutes(state.accessTokenExpiryTime, new Date()) <= 5 // treat expiry as 5 minutes before actual expiry time
    }

    /** 
     * Artificially keeps track of when the login (refresh token) expires.
     * 
     * Unfortunately MSAL.JS library does not provide programmatic expiry information for the underlying refresh token,
     * but we know according to docs, that for a webapp, the expiry time is capped at 1 day.
     * 
     * To err on the "safe" side, we assume expiration to have occured if 23 or more hours have elapsed since last login.
     */
    function isLoginTimelimitExceeded() {
        if (state.lastLoginTimestamp == null) {
            return true
        }
        return differenceInHours(new Date(), state.lastLoginTimestamp) >= 23
    }

    return {
        getAccessToken,
        user: computed(() => state.user.value),
        isAccessTokenAvailable,
        isAccessTokenExpired,
        isAuthenticated,
        login,
        logout, 
        refreshSession
    }
}

//////////////////////////////////////////////////////////////////////////////////
//
//                           MSAL LIBRARY SETUP
//
//////////////////////////////////////////////////////////////////////////////////

function initializeMsalNativeBrowserAuthentication() {
    const msal = new PublicClientApplication({
        auth: {
            authority: 'https://login.microsoftonline.com/fdfed7bd-9f6a-44a1-b694-6e39c468c150/',
            clientId: appConfig.msalClientId,
            redirectUri: appConfig.msalNativeRedirectUrl,
            navigateToLoginRequestUrl: false,
        },
        cache: {
            cacheLocation: 'localStorage'
        },
        system: {
            //navigationClient: implement this if we need to try out embedded browsers again
            loggerOptions: {
                logLevel: LogLevel.Warning,
                loggerCallback: (level, message, containsPersonalIdentifiableInformation) => {
                    if (containsPersonalIdentifiableInformation) {
                        return
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message)
                            return
                        case LogLevel.Info:
                            console.info(message)
                            return
                        case LogLevel.Verbose:
                            console.debug(message)
                            return
                        case LogLevel.Warning:
                            console.warn(message)
                            return
                    }
                },
                piiLoggingEnabled: false
            },
        }
    })

    return msal
}

/**
 * Adds a native app universal link listener, to capture the authentication response from MSAL.
 */
function attachNativeUniversalLinkListener() {
    const isRedirectUri = (url: string) => url.includes(appConfig.msalNativeRedirectUrl)
    const parseAuthCodeFragment = (url: string) => url.split('#')[1]; // get hash fragment part of the redirect URL, this contains MSAL auth code and state data

    App.addListener('appUrlOpen', ({ url }) => {
        if (isRedirectUri(url)) {
            const authCodeFragment = parseAuthCodeFragment(url)
            notifyMsalRedirectCallback(authCodeFragment)
        }
    })
}

/**
 * Notify login client code once a redirect callback is received from a native universal link.
 * This callback will contain the authCode (and a few other parameters) as part of the URL hash fragment.
 * 
 * This URL hash fragment must then be passed into the MSAL library for completing the login flow and acquiring a token.
 * 
 * @param authCode 
 */
 function notifyMsalRedirectCallback(authCode: string) {
    if (callbackListener) {
        callbackListener(authCode)
    } else {
        console.warn('Received MSAL redirect callback, but no listener attached.')
    }
}

/**
 * Used by login code to add a listener to be notified when MSAL returns back from redirect.
 * 
 * @returns 
 */
function getAuthCodeFragmentFromNativeRedirect() {
    return new Promise<string>(resolve => {
        callbackListener = (authCode) => {
            resolve(authCode)
            callbackListener = undefined
        }
    })
}

/**
 * There's not really any good way to detect if a build was run from TestFlight,
 * so we assume any build that is not production to fall into this category ;)
 * 
 * We check the app to see if it is in "paused" state, this indicates that the
 * Test Flight "what to test" message is showing, effectively putting the DView
 * app in background / paused mode. Now, some code can still execute in the
 * background, but certain things are disabled, for example opening of an external
 * browser window, such as the one used during the login flow. This would cause
 * the login to think it is continuing on in good faith, but would stall on waiting
 * for a universal link callback to occur, since the browser was never opened.
 * 
 * Update this method if better TestFlight detection mechanisms are discovered.
 * One way could be to also check for push notification environment, eg. the
 * aps-environment in the entitlements, but not sure of a good way to do this.
 */
async function isRunningViaAppleTestFlight() {
    const { isAppPaused } = useNative()

    const paused = await isAppPaused()
    const isDevBuild = !appConfig.environment.production

    return paused && isDevBuild
}

/**
 * Basically just wait for the app to come back from a paused state, caused by TestFlight.
 * 
 * @returns 
 */
function waitForTestFlightMessageToDisappear() {
    const { onAppResume } = useNative()
    
    return new Promise<void>(resolve => {
        const removeListener = onAppResume(() => {
            removeListener()
            resolve()
        })
    })
}

let callbackListener: MsalCallbackListenerFunction | undefined;

type MsalCallbackListenerFunction = (authCodeFragment: string) => void