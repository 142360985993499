import { useAuth } from '@dview/logic/use-auth'
import axios from 'axios'

export function setupAxiosAuthInterceptor() {
    axios.interceptors.request.use(function(config) {
        const { getAccessToken } = useAuth()
        const token = getAccessToken()
        config.headers.Authorization = `Bearer ${token}`
        return config
    })
}
