import axios from 'axios'
import { handleBackendError } from './errorhandling'
import { dviewApiUrl } from './util'

export interface PushNotificationsService {
    registerPushDevice(deviceToken: string): Promise<string>
    unregisterPushDevice(): Promise<string>
    sendCustomNotification(notification: CustomPushNotificationRequest): Promise<CustomPushNotificationResponse>
}

export class PushNotificationsServiceApi implements PushNotificationsService {
    
    registerPushDevice(deviceToken: string): Promise<string> {
        const request: PushNotificationRequest = {
            deviceToken
        }

        return axios
            .post<any, any>(dviewApiUrl('registerNativeDevice'), request)
            .then(result => result.data)
            .catch(handleBackendError)
    }

    unregisterPushDevice(): Promise<string> {
        return axios
            .post<any, any>(dviewApiUrl('unregisterNativeDevice'))
            .then(result => result.data)
            .catch(handleBackendError)
    }

    sendCustomNotification(request: CustomPushNotificationRequest): Promise<CustomPushNotificationResponse> {
        return axios
            .post<any, any>(dviewApiUrl('sendCustomNotification'), request)
            .then(result => result.data)
            .catch(handleBackendError)
    }
}

interface PushNotificationRequest {
    deviceToken?: string
    message?: string
}

export interface CustomPushNotificationRequest {
    message: string
    title?: string
}

export interface CustomPushNotificationResponse {
    numberOfNotificationsSent: number
    numberOfNotificationsFailed: number
}
