import { computed, reactive } from 'vue'
import { addDays, differenceInCalendarDays, differenceInMinutes, isWithinInterval, subDays } from 'date-fns'
import {
    Deviation,
    DeviationFlags,
    DeviationMetadata,
    DeviationRatingInfo,
    DeviationType,
    DeviationVm,
    OrganizationUnit,
} from '@dview/shared/models'
import { DViewBackendError, useBackend } from './use-backend'
import { useNovoGlow } from './use-novoglow'
import { useAppInsights } from '@dview/shared/util'
import { useDeviationSummary } from './use-deviation-summary'

// singleton state instance for entire app
const openDeviationsState = createState()
const closedDeviationsState = createState()
const hocRatedDeviationsState = createState()

/**
 * This represents the deviation data used to visualize all the deviations in the
 * deviation list views.
 *
 * @param type
 * @returns
 */
export function useDeviations(type: DeviationType) {
    const { deviationsService } = useBackend()
    const { trackEvent } = useAppInsights()
    const { refresh: refreshNovoGlowTimestamp } = useNovoGlow()

    const state = getState(type)

    async function loadDeviations(filters: OrganizationUnit[]) {
        try {
            state.error = false
            state.empty = false
            state.loaded = false
            state.loading = true

            // update NovoGlow timestamp information whenever a deviation query is run
            refreshNovoGlowTimestamp()

            const deviations = await loadDeviationsFromBackend(filters)
            state.deviations = deviations

            state.empty = state.deviations.length === 0
            state.loaded = true
            state.lastUsedFilter = filters
            state.lastUpdated = new Date()

            trackEvent({
                name: `User sees ${type} deviations`,
                properties: {
                    count: state.deviations.length,
                },
            })
        } catch (e) {
            console.error(e)
            if (e instanceof DViewBackendError) {
                // ... could do some fine grained eror handling here with an error object from server
            }
            state.error = true
        } finally {
            state.loading = false
        }
    }

    async function loadDeviationsFromBackend(orgFilters: OrganizationUnit[]) {
        let deviations: Deviation[] = []

        if (type === 'open') {
            deviations = await deviationsService.getOpenDeviations(orgFilters)
        } else if (type === 'closed') {
            const { period } = useDeviationSummary()
            deviations = await deviationsService.getClosedDeviations(orgFilters, period.value)
        } else if (type === 'hoc-rated') {
            deviations = await deviationsService.getHocRatedDeviations(orgFilters)
        }

        const mappedDeviations = deviations.map(deviation => convertToViewModel(deviation, type))

        return mappedDeviations
    }

    /**
     * Refreshes the deviation data, if sufficient time has passed, given last used filter.
     */
    function refresh() {
        if (state.lastUsedFilter == null || state.loading) {
            return
        }

        if (deviationsNeedsUpdating()) {
            loadDeviations(state.lastUsedFilter)
        }
    }

    /**
     * Returns true if 15 minutes have passed since deviations was last fetched from backend.
     */
    function deviationsNeedsUpdating() {
        return differenceInMinutes(new Date(), state.lastUpdated) > 15
    }

    return {
        error: computed(() => state.error),
        loading: computed(() => state.loading),
        loaded: computed(() => state.loaded),
        empty: computed(() => state.empty),
        deviations: computed(() => state.deviations),
        loadDeviations,
        refresh,
    }
}

function getState(type: DeviationType): DeviationsState {
    if (type === DeviationType.Open) return openDeviationsState
    else if (type === DeviationType.Closed) return closedDeviationsState
    else if (type === DeviationType.HoC) return hocRatedDeviationsState
    else throw new Error('Invalid deviation type passed to useDeviations()')
}

function createState(): DeviationsState {
    return reactive({
        deviations: [] as DeviationVm[],
        loading: true,
        loaded: false,
        empty: false,
        error: false,
        lastUsedFilter: null!,
        lastUpdated: new Date(),
        filterWatchApplied: false,
    })
}

function convertToViewModel(deviation: Deviation, type: DeviationType): DeviationVm {
    const deviationVm = {
        id: deviation.caseId,
        novoglowAppId: deviation.etqAppId,
        title: deviation.title,
        process: deviation.process,
        classification: deviation.classification,
        phase: deviation.phase,
        status: deviation.status,
        type: type,
        date: {
            created: deviation.createdDate,
            start: deviation.startDate,
            approved: deviation.approvedDate!,
            ratingDate: deviation.latestRatingDate!,
            discovery: deviation.discoveryDate,
        },
        organization: {
            svp: deviation.svp1,
            cvp: deviation.cvp,
            department: deviation.dept,
            team: deviation.team,
        },
    } as DeviationVm

    addDeviationMetadata(deviation, deviationVm)
    addRatingInfo(deviation, deviationVm)
    addDeviationFlags(deviationVm)

    return deviationVm
}

function addDeviationMetadata(source: Deviation, target: DeviationVm) {
    const metadata: DeviationMetadata = {
        batchRelated: source.batchRelated,
        leadtimeDays: null!,
        newlyAdded: isNewDeviation(target),
        environmentalMonitoring: isEnvironmentalMonitoringDeviation(target),
    }

    if (target.type === DeviationType.Open) {
        const daysSinceDiscovered = differenceInCalendarDays(new Date(), target.date.discovery)
        metadata.leadtimeDays = daysSinceDiscovered + 1 //The discovery date is actually day 1, not 0, so adding 1 to the result
    }

    target.metadata = metadata
}

const emTitleKeywords = [
    ' em ',
    ' env ',
    ' environmental monitoring ',
    ' cfu ',
    ' action limit ',
    ' alert limit ',
    ' alarm limit ',
    ' action exceedance ',
    ' excursion ',
]
const emProcessKeywords = [' s2.03 ', ' s2.05 ', ' s2.08 ', ' s2.09 ', ' s2.10 ', ' cs3.03 ', ' cs3.06.03 ']

function isEnvironmentalMonitoringDeviation(deviation: DeviationVm) {
    const title = deviation.title?.toLowerCase() ?? ''
    const process = deviation.process?.toLowerCase() ?? ''

    let emMatch = false
    for (const emKeyword of emTitleKeywords) {
        if (title.includes(emKeyword)) {
            emMatch = true
            break
        }
    }

    if (!emMatch) {
        return false
    }

    emMatch = false
    for (const emKeyword of emProcessKeywords) {
        if (process.includes(emKeyword)) {
            emMatch = true
            break
        }
    }

    return emMatch
}

function isNewDeviation(deviation: DeviationVm) {
    const today = new Date(new Date().toDateString()) // This ensures that we don't consider the time of day, only date
    const timespanLast24h = { start: subDays(today, 1), end: addDays(today, 1) }

    let deviationDate: Date = null!

    if (deviation.type === DeviationType.Closed) deviationDate = deviation.date.approved
    if (deviation.type === DeviationType.HoC) deviationDate = deviation.date.ratingDate
    if (deviation.type === DeviationType.Open) deviationDate = deviation.date.created

    return isWithinInterval(deviationDate, timespanLast24h)
}

function addRatingInfo(source: Deviation, target: DeviationVm) {
    const ratingInfo: DeviationRatingInfo = {
        hocRated: false,
        hocRating: null!,
    }

    if (target.type === DeviationType.HoC) {
        ratingInfo.hocRated = true
        ratingInfo.hocRating = source.hocRating!
    }

    target.ratingInfo = ratingInfo
}

function addDeviationFlags(deviation: DeviationVm) {
    const flags: DeviationFlags = {
        leadtime: false,
    }

    if (deviation.type === DeviationType.Open) {
        if (deviation.metadata.leadtimeDays >= 25) {
            flags.leadtime = true
        }
    }

    deviation.flags = flags
}

interface DeviationsState {
    deviations: DeviationVm[]
    empty: boolean
    loading: boolean
    loaded: boolean
    error: boolean // could be fine grained specific deviations error object, but lets wait until we know more
    lastUsedFilter: OrganizationUnit[] // used by data refresh operation
    lastUpdated: Date // indicates when data was last fetched from backend API
    filterWatchApplied: boolean
}
