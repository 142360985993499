import { NotificationMode, OrganizationUnit, UserPreferences, NotificationSettings } from '@dview/shared/models'
import { UserPreferencesService } from '../userpreferences-api'
import { fakeEndpointDelay } from './backend-mock-util'

export class UserpreferencesServiceMock implements UserPreferencesService {
    async getUserPreferences() {
        await fakeEndpointDelay('fast')
        return getLocalStoragePreferences()
    }

    async updateNotificationSettings(notificationSettings: NotificationSettings) {
        await fakeEndpointDelay('fast')
        return saveLocalStoragePreference(preferences => {
            preferences.notifications = notificationSettings
        })
    }

    async updateOrganizationFilter(filter: OrganizationUnit[]) {
        await fakeEndpointDelay('fast')
        return saveLocalStoragePreference(preferences => (preferences.orgFilter = filter))
    }
}

function getLocalStoragePreferences() {
    const saved = localStorage.getItem('userpreferences')
    const prefs = saved ? (JSON.parse(saved) as UserPreferences) : ({} as UserPreferences)
    return Promise.resolve(prefs)
}

async function saveLocalStoragePreference(updateAction: (preferences: UserPreferences) => void) {
    const preferences = await getLocalStoragePreferences()
    updateAction(preferences)
    preferences.userOnboarded = true
    saveLocalStoragePreferences(preferences)
}

function saveLocalStoragePreferences(preferences: UserPreferences) {
    localStorage.setItem('userpreferences', JSON.stringify(preferences))
}
