import { NavigationFailure, RouteLocationRaw, Router } from 'vue-router'

let routerInstance: Router | undefined
let appEntryRoute: SimpleRoute | undefined

/**
 * !! NOT MEANT TO REPLACE VUE-ROUTER !!
 * 
 * This is a tiny hack, in order to have a router in hand for the cases where the code
 * executes outside of Vue, in an async context, for example when getting callbacks from
 * native events through Capacitor.
 * 
 * !! NOT MEANT TO REPLACE VUE-ROUTER !!
 */
export function useDViewGlobalRouter() {

    // hidden function, not exposed by API
    function configure(router: Router) {
        routerInstance = router
    }

    function push(to: RouteLocationRaw): Promise<NavigationFailure | void | undefined> {
        if (!routerInstance) {
            console.error('Trying to use DView global router without a router instance configured.')
            return Promise.reject()
        }

        return routerInstance.push(to)
    }

    function replace(to: RouteLocationRaw): Promise<NavigationFailure | void | undefined> {
        if (!routerInstance) {
            console.error('Trying to use DView global router without a router instance configured.')
            return Promise.reject()
        }

        return routerInstance.replace(to)
    }

    /**
     * Pushes an "app entry" route, typically meant to be grabbed by the login flow after authentication is complete,
     * in order to:
     *      - return to the in-app route the user was last on
     *      - go to the URL bookmark from which the app was started
     *      - go to a push notification event route action
     * 
     * @param to 
     */
    function pushAppEntryRoute(to: SimpleRoute) {
        appEntryRoute = to
        const path = to.path
        saveAppEntryRouteToStorage({ path })
    }

    /**
     * Gets a saved entry route, and deletes it if one exists.
     * 
     * @returns 
     */
    function popAppEntryRoute() {
        let route = appEntryRoute ?? getAppEntryRouteFromStorage()

        // null safe guard for client caller if no app entry route specified, defaults to root app route
        if (!route) {
            route = {
                path: '/',
            } as SimpleRoute
        }

        appEntryRoute = null!
        localStorage.removeItem('app-entry-route')

        return route
    }

    function saveAppEntryRouteToStorage(route: SimpleRoute) {
        localStorage.setItem('app-entry-route', JSON.stringify(route))
    }

    function getAppEntryRouteFromStorage(): SimpleRoute | null {
        const path = localStorage.getItem('app-entry-route')
        
        if (path) {
            return JSON.parse(path) as SimpleRoute
        } else {
            return null
        }
    }
    
    function isAppEntryRouteAvailable() {
        return !!appEntryRoute || !!getAppEntryRouteFromStorage()
    }

    function getCurrentRoute() {
        return String(routerInstance?.currentRoute?.value.name)
    }

    return {
        configure,
        push,
        replace,
        isAppEntryRouteAvailable,
        popAppEntryRoute,
        pushAppEntryRoute,
        getCurrentRoute
    } as DViewGlobalRouter
}

interface DViewGlobalRouter {
    push(to: RouteLocationRaw): Promise<NavigationFailure | void | undefined>
    replace(to: RouteLocationRaw): Promise<NavigationFailure | void | undefined>
    isAppEntryRouteAvailable(): boolean
    popAppEntryRoute(): SimpleRoute
    pushAppEntryRoute(to: SimpleRoute): void
    getCurrentRoute(): string
}

interface SimpleRoute {
    path: string
}