import { computed, reactive, readonly } from 'vue'
import { useBackend } from './use-backend'
import { differenceInCalendarDays, differenceInMinutes, format, intervalToDuration, isToday, isYesterday } from 'date-fns'
import { sleep } from '@dview/shared/util/async'

const state = reactive<NovoGlowState>({
    loading: true,
    loaded: false,
    error: false,
    lastUpdated: {
        text: '',
        timestamp: null!
    }
})

export function useNovoGlow() {
    const { deviationsService } = useBackend()

    async function refresh() {
        try {
            state.error = false
            state.loaded = false
            state.loading = true

            const date = await deviationsService.getNovoGlowLastUpdated()
            
            // since the backend call above will likely respond in very few milliseconds, this artificial delay is intentional, 
            // to purposefully give the novoglow update pulse animation time to be slightly noticed, and thus instill a sense of
            // serenity in the user's mind about the source validity of the deviation data he / she is currently viewing.
            await sleep(3000) 

            Object.assign(state.lastUpdated, formatNovoGlowDate(date)) // can't replace object reference, as it would break readonly() proxy in return statement

            state.loaded = true
        } catch (e) {
            console.error(e)
            formatNovoGlowDate(null!)
            state.error = true
        } finally {
            state.loading = false
        }
    }

    function formatNovoGlowDate(novoGlowTimestamp: Date): NovoGlowLastUpdated {
        const lastUpdated: NovoGlowLastUpdated = {
            timestamp: novoGlowTimestamp,
            text: ''
        }

        if (!lastUpdated.timestamp) {
            lastUpdated.text = 'Unknown'
        } else {
            const novoGlowDate = lastUpdated.timestamp
            if (isToday(novoGlowDate)) {
                const now = new Date()
                const duration = intervalToDuration({ start: now, end: novoGlowTimestamp })

                let text = ''
                if (duration.hours! > 0) {
                    text = getPluralHours(duration.hours)
                    if (duration.minutes! > 0) {
                        text = text + ' ' + `${duration.minutes} min`
                    }
                } else {
                    text = `${duration.minutes} min`
                }

                lastUpdated.text = text + ' ago'
            } else if (isYesterday(novoGlowDate)) {
                lastUpdated.text = 'Yesterday'
            } else if (differenceInCalendarDays(new Date(), novoGlowDate) < 7) {
                lastUpdated.text = format(novoGlowDate, 'EEEE')
            } else {
                const daysAgo = differenceInCalendarDays(new Date(), novoGlowDate)
                lastUpdated.text = `${daysAgo} days ago`
            }
        }

        return lastUpdated
    }

    return {
        error: computed(() => state.error),
        loading: computed(() => state.loading),
        loaded: computed(() => state.loaded),
        lastUpdated: readonly(state.lastUpdated),
        refresh,
    }
}

function getPluralHours(hours?: number) {
    if (hours === 1) {
        return '1 hour'
    } else {
        return `${hours} hours`
    }
}

interface NovoGlowState {
    lastUpdated: NovoGlowLastUpdated
    loading: boolean
    loaded: boolean
    error: boolean
}

interface NovoGlowLastUpdated {
    timestamp: Date
    text: string
}