//////////////////////////////////////////////////////////////////////////////
//
//                             API MODELS
//
//////////////////////////////////////////////////////////////////////////////
export interface Deviation {
    svp1: string
    svp2: string
    cvp: string
    dept: string
    team: string
    caseId: string
    etqAppId: number
    createdDate: Date
    startDate: Date
    discoveryDate: Date
    approvedDate?: Date // only available for closed deviations
    latestRatingDate?: Date // only available for closed deviations
    classification: DeviationClassification
    phase: DeviationPhase
    status: DeviationStatus
    title: string
    batchRelated: boolean
    hocRating?: number // only available for closed deviations
    process: string
}

export enum DeviationClassification {
    Unclassifed = 0,
    Major = 1,
    Minor = 2,
}

export enum DeviationStatus {
    Open = 0,
    Closed = 1,
}

export enum DeviationPhase {
    New = 0,
    Approval = 1,
    Completed = 2,
    Cancelled = 3,
}

export interface DeviationSummary {
    openDeviations: {
        currentTotal: number
        periodTotal: number
        periodMajor: number
        periodMinor: number
        periodUnclassified: number
    }
    closedDeviations: {
        periodTotal: number
    }
    hocRatings: {
        periodRating0: number
        periodRating1: number
        periodRating2: number
        periodRating3: number
        periodRating4: number
        periodRating5: number
        periodRating6: number
    }
}

export type DeviationSummaryPeriod = '24hours' | 'weekly' | 'monthly'

//////////////////////////////////////////////////////////////////////////////
//
//                         FRONTEND VIEW MODELS
//
//////////////////////////////////////////////////////////////////////////////
export interface DeviationVm {
    id: string // the unique case id number for the deviation
    novoglowAppId: number // for use when linking to novoGloW deviation data

    title: string
    process: string

    date: {
        created: Date
        start: Date // the date a person started working on
        approved: Date
        ratingDate: Date // the date the deviation was given a HoC rating
        discovery: Date
    }

    classification: DeviationClassification
    phase: DeviationPhase
    status: DeviationStatus
    type: DeviationType // indicates what type of deviation this is; open, closed, hoc-rated...

    ratingInfo: DeviationRatingInfo
    metadata: DeviationMetadata

    // indicators for the UI, denoting whether a deviation should be visibly highlighted due to some condition
    flags: DeviationFlags

    // the organizational level this deviation belongs to
    organization: {
        svp: string
        // vp: string // we don't yet use this?
        cvp: string
        department: string
        team: string
    }
}

export interface DeviationMetadata {
    newlyAdded: boolean
    batchRelated: boolean
    leadtimeDays: number
    environmentalMonitoring: boolean
}

export interface DeviationFlags {
    leadtime: boolean // the deviation is getting near to closing deadline
}

export interface DeviationRatingInfo {
    hocRated: boolean
    hocRating: number
}

export enum DeviationType {
    Closed = 'closed',
    HoC = 'hoc-rated',
    Open = 'open',
}
