import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useAuth } from '@dview/logic/use-auth'
import { RefreshSessionStatus } from '@dview/logic/auth/auth.interface'
import { useDViewGlobalRouter } from '@dview/core/use-dview-global-router'

export async function AuthenticatedGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    if (isExemptFromRouteGuard(to)) {
        return next()
    }

    const authenticated = await isAuthenticated()

    if (to.name === 'Login' || authenticated) {
        //User is accessing the /login page or the user is already logged in. Allowing the request to go through
        return next()
    } else {
        // User is not logged in and is accessing another site than /login, so save the intended route the user
        // wanted to go to, and redirect him to the login flow.
        const router = useDViewGlobalRouter()
        router.pushAppEntryRoute(to)
        return next({ name: 'Login' })
    }
}

function isExemptFromRouteGuard(route: RouteLocationNormalized) {
    return route.meta.exemptFromRouteGuards === true
}

/**
 * Checks if the user is actively logged in, and it not, tries to refresh the access token silently.
 * If refresh of access token fails, then caller should redirect user to login page for a new
 * interactive login flow.
 * 
 * @returns 
 */
async function isAuthenticated() {
    const { isAuthenticated, refreshSession } = useAuth()

    if (isAuthenticated()) {
        return true
    }

    const refreshStatus = await refreshSession()

    return refreshStatus === RefreshSessionStatus.Success
}
