import { appConfig } from '@dview/core/appconfig'
import { AuthenticatedUser } from '@dview/logic'

export function isUserDeveloper(user: AuthenticatedUser) {
    const environment = appConfig.environment.name
    if (environment === 'localhost' || environment === 'sandbox') {
        return true
    }

    const username = (user?.username ?? '').toLowerCase()
    for (const developer of DEVELOPERS) {
        if (username.indexOf(developer) !== -1) {
            return true
        }
    }
    return false
}

// add developer initials allowed to access the developer page as required here
const DEVELOPERS = ['tyqf', 'rzbi', 'nmen', 'skfd']