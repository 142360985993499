export interface OrganizationStructure {
    svps: OrganizationUnit[]
    cvps: OrganizationUnit[]
    vps: OrganizationUnit[]
    depts: OrganizationUnit[]
    teams: OrganizationUnit[]
}

export interface OrganizationUnit {
    type: OrganizationUnitType
    name: string
    relations: OrganizationalUnitRelation
}

export enum OrganizationUnitType {
    Svp = 0,
    Cvp = 1,
    Vp = 2,
    Department = 3,
    Team = 4
}

export interface OrganizationalUnitRelation {
    svp?: string
    cvp?: string
    vp?: string
    department?: string
}