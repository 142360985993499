import { Capacitor } from '@capacitor/core'
import { useBackend } from './use-backend'
import { useNative } from './use-native'

export function usePushNotification() {

    /**
     * Prompts user for push notification permission, then registers the device/app for push notifications
     * at Apple APNS. This returns a unique "device token" which is then sent to our own backend and further 
     * registered into Azure Notification Hubs.
     * 
     * @returns 
     */
    async function registerDevice(): Promise<boolean> {
        if (!Capacitor.isNativePlatform()) {
            return false
        }

        const { pushNotificationService } = useBackend()
        const { registerForPushNotificationsWithApple } = useNative()

        const status = await registerForPushNotificationsWithApple()
    
        if (status.state === 'registered') {
            try {
                await pushNotificationService.registerPushDevice(status.deviceToken!)
                return true
            }
            catch (e) {
                console.error('Error calling backend: ' + e)
                return false
            }
        } else if (status.state === 'registration-error') {
            console.error('Error registering for push notifications! ' + status.errorMessage)
        } else if (status.state === 'permission-error') {
            console.error('User denied push notification permissions: ' + status.errorMessage)
        }

        return false
    }

    /**
     * Unregisters current device from no longer receiving push notifications from backend.
     */
    async function unregisterDevice() {
        const { pushNotificationService } = useBackend()

        await pushNotificationService.unregisterPushDevice()
    }

    return {
        registerDevice,
        unregisterDevice
    }
}