import '@capacitor/core' // good practice to explicitly import Capacitor JavaScript bridge
import { createApp } from 'vue'
import {
    IonApp,
    IonBackButton,
    IonButtons,
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonicVue,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonTitle,
    IonToolbar,
    IonSpinner,
    IonGrid,
    IonRow,
    IonCol,
    IonItemGroup,
    IonSelect,
    IonSelectOption,
    IonSlides,
    IonSlide,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonModal,
    IonToggle,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonPopover,
    IonFooter,
} from '@ionic/vue'

import { appConfig } from './app/core'

import App from './app/App.vue'
import router from './app/router/routing'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* DView custom styles and Novo Nordisk colors / typography */
import './styles/styles.scss'

/* Ionic theme configuration and Ionic component style modifications */
import './theme/theme.scss'

/* Azure Application Insights */
import { useAppInsights } from '@dview/shared/util'
import { ITelemetryItem } from '@microsoft/applicationinsights-web'
import { useAuth } from '@dview/logic/use-auth'

/* Create Vue root app */
const app = createApp(App)
    .use(IonicVue, {
        swipeBackEnabled: false
    })
    .use(router)

/* Register commonly used Ionic components globally */
app.component('ion-app', IonApp)
app.component('ion-page', IonPage)
app.component('ion-content', IonContent)
app.component('ion-header', IonHeader)
app.component('ion-list', IonList)
app.component('ion-list-header', IonListHeader)
app.component('ion-item', IonItem)
app.component('ion-item-group', IonItemGroup)
app.component('ion-toolbar', IonToolbar)
app.component('ion-title', IonTitle)
app.component('ion-tabs', IonTabs)
app.component('ion-tab-bar', IonTabBar)
app.component('ion-tab-button', IonTabButton)
app.component('ion-icon', IonIcon)
app.component('ion-label', IonLabel)
app.component('ion-buttons', IonButtons)
app.component('ion-button', IonButton)
app.component('ion-back-button', IonBackButton)
app.component('ion-grid', IonGrid)
app.component('ion-row', IonRow)
app.component('ion-col', IonCol)
app.component('ion-spinner', IonSpinner)
app.component('ion-select', IonSelect)
app.component('ion-select-option', IonSelectOption)
app.component('ion-slides', IonSlides)
app.component('ion-slide', IonSlide)
app.component('ion-menu', IonMenu)
app.component('ion-menu-button', IonMenuButton)
app.component('ion-menu-toggle', IonMenuToggle)
app.component('ion-toggle', IonToggle)
app.component('ion-infinite-scroll', IonInfiniteScroll)
app.component('ion-infinite-scroll-content', IonInfiniteScrollContent)
app.component('ion-footer', IonFooter)
app.component('ion-modal', IonModal)
app.component('ion-popover', IonPopover)

if (appConfig.mock.backend) {
    console.warn('DView mocked backend is currently enabled.')
}

if (appConfig.mock.login) {
    console.warn('DView mocked login flow is currently enabled.')
}

const { initAppInsights, addTelemetryInitializer } = useAppInsights()
const { user } = useAuth()

initAppInsights()

/* Using AppInsight's Telemetry Initializer to replace the randomly generated id with end-user's Novo initials */
addTelemetryInitializer((envelope: ITelemetryItem) => {
    if (envelope.ext) {
        envelope.ext.user.id = user.value.name
    }
})

/* Preliminary binding errorHandler to Azure App Insights */
app.config.errorHandler = (err, vm, info) => {
    console.error(err)
    const { trackException } = useAppInsights()
    trackException({exception: err as Error | undefined})
}

/* Boostrap app */
router.isReady().then(() => {
    app.mount('#app')
})
