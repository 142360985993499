import { DeviationClassification, DeviationPhase, DeviationType, DeviationVm } from '@dview/shared/models'
import { computed, reactive, Ref } from 'vue'

/**
 * Handles filtering of deviation data, whenever source input deviations change or whenever
 * a change to the active filter selection has occured.
 * 
 * @param sourceDeviations 
 * @param type 
 * @returns 
 */
export function useDeviationsFilter(sourceDeviations: Ref<DeviationVm[]>, type: DeviationType) {
    const { sortDirection, sortKey, filterKey } = useDeviationsFilterConfig(type)
    const filteredDeviations = computed(() => applyFilteringAndSorting(sourceDeviations.value)) 

    const descending = computed(() => sortDirection.value === 'descending' ? -1 : 1)

    function applyFilteringAndSorting(deviations: DeviationVm[]) {
        const filtered = applyFiltering(deviations)
        applySorting(filtered)

        return filtered
    }

    function applyFiltering(deviations: DeviationVm[]) {
        const filter = filterKey.value

        return deviations.filter(deviation => {
            if (filter == null) {
                return true
            }

            // common filters
            if (filter === 'environmental-monitoring') {
                return deviation.metadata.environmentalMonitoring
            }
            // open deviations filter options
            else if (filter === 'major') {
                return deviation.classification === DeviationClassification.Major
            } else if (filter === 'minor') {
                return deviation.classification === DeviationClassification.Minor
            } else if (filter === 'batch') {
                return deviation.metadata.batchRelated == true
            } else if (filter === 'leadtime') {
                return deviation.flags.leadtime
            }
            // hoc rated deviations filter options
            else if (filter === 'hoc-low-rated') {
                return deviation.ratingInfo.hocRating < 5
            } else if (filter === 'hoc-high-rated') {
                return deviation.ratingInfo.hocRating > 4
            }
        })
    }

    function applySorting(deviations: DeviationVm[]) {
        const sort = sortKey.value
        const desc = descending.value

        // open deviations (mostly...) sort options
        if (sort === 'date') {
            if (type === 'open') {
                deviations.sort((a, b) => desc * (a.date.created.getTime() - b.date.created.getTime()))
            } else if (type === 'hoc-rated') {
                deviations.sort((a, b) => desc * (a.date.ratingDate.getTime() - b.date.ratingDate.getTime()))
            }
        } else if (sort === 'classification') {
            deviations.sort(compareByClassification)
        } else if (sort === 'flagged') {
            deviations.sort(compareByFlagged)
        } else if (sort === 'phase') {
            deviations.sort(compareByPhase)
        }
        // hoc rated deviations sort options
        else if (sort === 'hoc-rating') {
            deviations.sort(compareByHocRating)
        }
    }

    const compareByClassification = function() {
        // classification enum isn't suited for sorting, so we need to manually specify weight
        const sortingWeight = {
            [DeviationClassification.Unclassifed]: 0,
            [DeviationClassification.Minor]: 1,
            [DeviationClassification.Major]: 2
        }
    
        return (a: DeviationVm, b: DeviationVm) => {
            // secondary sort, always sort by date descending
            if (sortingWeight[a.classification] === sortingWeight[b.classification]) {
                return b.date.created.getTime() - a.date.created.getTime()
            }
    
            return descending.value * (sortingWeight[a.classification] - sortingWeight[b.classification])
        }
    }()

    const compareByPhase = function() {
        // classification enum isn't suited for sorting, so we need to manually specify weight
        const sortingWeight = {
            [DeviationPhase.Cancelled]: 0,
            [DeviationPhase.Completed]: 1,
            [DeviationPhase.Approval]: 2,
            [DeviationPhase.New]: 3
        }
    
        return (a: DeviationVm, b: DeviationVm) => {
            // secondary sort, always sort by date descending
            if (sortingWeight[a.phase] === sortingWeight[b.phase]) {
                return b.date.created.getTime() - a.date.created.getTime()
            }
    
            return descending.value * (sortingWeight[a.phase] - sortingWeight[b.phase])
        }
    }()
    
    function compareByFlagged(a: DeviationVm, b: DeviationVm) {
        const flagA = a.flags.leadtime ? 1 : 0
        const flagB = b.flags.leadtime ? 1 : 0
    
        // secondary sort, always sort by date descending
        if (flagA === flagB) {
            return b.date.created.getTime() - a.date.created.getTime()
        }
    
        return descending.value * (flagA - flagB)
    }
    
    function compareByHocRating(a: DeviationVm, b: DeviationVm) {
        // secondary sort, always sort by date descending
        if (a.ratingInfo.hocRating === b.ratingInfo.hocRating) {
            return b.date.ratingDate.getTime() - a.date.ratingDate.getTime()
        }
    
        return descending.value * (a.ratingInfo.hocRating - b.ratingInfo.hocRating)
    }

    return {
        deviations: filteredDeviations
    }
}

////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////

// 2021.09.24: Temporarily disabled functionality to persist filter selection until next app start.
//const { load: loadFilterSettings, save: saveFilterSettings } = useLocalStorage()

const openDeviationsState = createState(DeviationType.Open)
const closedDeviationsState = createState(DeviationType.Closed)
const hocRatedDeviationsState = createState(DeviationType.HoC)

/** 
 * Composable used to represent the actual selected filter configuration for a given type of deviations.
 * This in and of itself does not perform any filtering, make use of "useDeviationsFilter" composable
 * for that, which in turns relies on this filter config composable.
 */
export function useDeviationsFilterConfig(type: DeviationType) {
    const state = getState(type)

    function setFilterKey(key: DeviationFilteringKey) {
        state.filtering.key = key
        markFilterAppliedByUser()
        saveFilter()
    }

    function setSortingDirection(direction: DeviationSortingDirection) {
        state.sorting.direction = direction
        markFilterAppliedByUser()
        saveFilter()
    }

    function setSortingKey(key: DeviationSortingKey) {
        state.sorting.key = key
        markFilterAppliedByUser()
        saveFilter()
    }

    function markFilterAppliedByUser() {
        state.appliedBy = 'user-interaction'
    }

    function markFilterAppliedByApp() {
        state.appliedBy = 'app-logic'
    }

    function saveFilter() {
        // 2021.09.24: Temporarily disabled functionality to persist filter selection until next app start.
        // saveFilterSettings(`${type}-filtersettings`, toRaw(state))
    }

    function reset() {
        setFilterKey(null!)
        setSortingDirection('descending'),
        setSortingKey('date')
        state.appliedBy = 'user-interaction'
    }

    return {
        active: computed(() => state.filtering.key != null), // indicates if filtering is currently enabled
        sortDirection: computed(() => state.sorting.direction),
        sortKey: computed(() => state.sorting.key),
        filterKey: computed(() => state.filtering.key),
        appliedBy: computed(() => state.appliedBy),
        setFilterKey,
        setSortingKey,
        setSortingDirection,
        markFilterAppliedByUser,
        markFilterAppliedByApp,
        reset
    }
}

function createState(type: DeviationType): DeviationFilterState {
    const defaultState: DeviationFilterState = {
        sorting: {
            direction: 'descending',
            key: 'date'
        },
        filtering: {
            key: null!
        },
        appliedBy: 'user-interaction'
    }

    // 2021.09.24: Temporarily disabled functionality to persist filter selection until next app start.
    //const loadedState = loadFilterSettings(`${type}-filtersettings`, defaultState)

    return reactive(defaultState)
}

function getState(type: DeviationType): DeviationFilterState {
    if (type === 'open') return openDeviationsState
    else if (type === 'closed') return closedDeviationsState
    else if (type === 'hoc-rated') return hocRatedDeviationsState
    else throw new Error('Invalid deviation type passed to useDeviationFilter()')
}

interface DeviationFilterState {
    sorting: {
        direction: DeviationSortingDirection
        key: DeviationSortingKey
    },
    filtering: {
        key: DeviationFilteringKey
    },
    appliedBy: 'app-logic' | 'user-interaction' // indicates if the active filter / sorting is set by the app (implicitly) or set by the user (explicitly)
}

export type DeviationSortingDirection = 'ascending' | 'descending'
export type DeviationSortingKey = 'date' | 'hoc-rating' | 'flagged' | 'classification' | 'phase'
export type DeviationFilteringKey = 'major' | 'minor' | 'environmental-monitoring' | 'hoc-low-rated' | 'hoc-high-rated' | 'leadtime' | 'batch'