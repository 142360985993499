import _ from 'lodash'
import { appConfig } from '@dview/core'
import { sleep } from '@dview/shared/util/async'

type DelayRealism = 'instant' | 'fast' | 'normal' | 'slow'

const delayTimings = {
    'instant': { min: 10, max: 25 },
    'fast': { min: 50, max: 150 },
    'normal': { min: 250, max: 1000 },
    'slow': { min: 1500, max: 3500 },
}

export async function fakeEndpointDelay(delayRealism: DelayRealism = 'normal') {
    if (!appConfig.mock.backendFakeDelay) {
        return Promise.resolve()
    }

    const { min, max } = delayTimings[delayRealism]
    const delay = _.random(min, max)
    await sleep(delay)
}