import { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from '@ionic/vue-router'
import { DViewPage } from '@dview/pages/pagenames'
import { AppInitedGuard, AuthenticatedGuard, OnboardedGuard, DeveloperGuard } from './guards'
import { TrackPageViewNavHook } from './navhooks'

const ErrorPage = () => import('@dview/pages/error/ErrorPage.vue')

const LoginPage = () => import('@dview/pages/authentication/LoginPage.vue')

const OnboardingPage = () => import('@dview/pages/onboarding/OnboardingPage.vue')

const DeviationsPage = () => import('@dview/pages/deviations/DeviationsPage.vue')
const DeviationsOverviewPage = () => import('@dview/pages/deviations/overview/DeviationsOverviewPage.vue')
const DeviationsOpenPage = () => import('@dview/pages/deviations/lists/DeviationsOpenPage.vue')
const DeviationsHocRatingsPage = () => import('@dview/pages/deviations/lists/DeviationsHocRatingsPage.vue')

const NotificationSettingsPage = () => import('@dview/pages/settings/NotificationSettingsPage.vue')
const OrganizationFilterSettingsPage = () => import('@dview/pages/settings/OrganizationFilterSettingsPage.vue')
const FeedbackAndSupportPage = () => import('@dview/pages/support/FeedbackSupportPage.vue')

const DeveloperPage = () => import('@dview/pages/developer/DeveloperPage.vue')
const DeveloperNotificationPage = () => import('@dview/pages/developer/DeveloperCustomNotification.vue')

const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        redirect: '/deviations',
    },
    {
        path: '/error',
        name: 'Error',
        component: ErrorPage,
        props: true,
        meta: {
            exemptFromRouteGuards: true,
        },
    },
    {
        path: '/developer',
        name: 'Developer',
        component: DeveloperPage,
        beforeEnter: [ DeveloperGuard ]   
    },
    {
        path: '/developer/notification',
        name: 'DeveloperNotification',
        component: DeveloperNotificationPage,
        beforeEnter: [ DeveloperGuard ]   
    },
    {
        path: '/login',
        name: DViewPage.Login,
        component: LoginPage,
        meta: {
            exemptFromRouteGuards: true,
        },
    },
    {
        path: '/onboarding',
        name: 'Onboarding',
        component: OnboardingPage,
    },
    {
        path: '/deviations',
        name: 'Deviations',
        component: DeviationsPage,
        beforeEnter: [ OnboardedGuard ],
        children: [
            {
                path: '',
                redirect: '/deviations/overview',
            },
            {
                path: 'overview',
                name: DViewPage.DeviationsOverview,
                component: DeviationsOverviewPage,
            },
            {
                path: 'open',
                name: DViewPage.DeviationsOpen,
                component: DeviationsOpenPage,
            },
            {
                path: 'hoc-ratings',
                name: DViewPage.DeviationsHocRatings,
                component: DeviationsHocRatingsPage,
            },
        ],
    },
    {
        path: '/settings/filter',
        name: 'OrganizationFilterSettings',
        component: OrganizationFilterSettingsPage,
    },
    {
        path: '/settings/notification',
        name: 'NotificationSetting',
        component: NotificationSettingsPage,
    },
    {
        path: '/support',
        name: 'FeedbackAndSupport',
        component: FeedbackAndSupportPage,
    },
]

const router = createRouter({
    history: createWebHistory(''), // process.env.BASE_URL
    routes,
})

router.beforeEach(AppInitedGuard) // ensures essential user data is loaded and ready
router.beforeEach(AuthenticatedGuard) // disallows entering any route unless authenticated, redirects to login page

router.afterEach(TrackPageViewNavHook) // manually call trackPageView to establish the current user/session/pageview

export default router
