import axios from 'axios'
import { Deviation, DeviationSummary, DeviationSummaryPeriod, OrganizationUnit } from '@dview/shared/models'
import { handleBackendError } from './errorhandling'
import { dviewApiUrl } from './util'
import { retryablePromise } from '../util/promise'

export interface DeviationsService {
    getClosedDeviations(filters: OrganizationUnit[], period: DeviationSummaryPeriod): Promise<Deviation[]>
    getOpenDeviations(filters: OrganizationUnit[]): Promise<Deviation[]>
    getHocRatedDeviations(filters: OrganizationUnit[]): Promise<Deviation[]>
    getNovoGlowLastUpdated(): Promise<Date>
    getDeviationSummary(period: DeviationSummaryPeriod): Promise<DeviationSummary>
}

export class DeviationsServiceApi implements DeviationsService {
    getClosedDeviations(filters: OrganizationUnit[], period: DeviationSummaryPeriod) {
        return retryablePromise(() => {
            return axios
                .post<Deviation[]>(dviewApiUrl('closed'), filters, { params: { period } })
                .then(result => {
                    //This is to ensure that the dates (received as strings from API) are parsed to JS dates
                    result.data.forEach(d => (d.createdDate = new Date(d.createdDate)))
                    result.data.forEach(d => (d.startDate = new Date(d.startDate)))
                    result.data.forEach(d => (d.discoveryDate = new Date(d.discoveryDate)))
                    result.data.forEach(d => (d.approvedDate = new Date(d.approvedDate!)))
                    return result.data
                })
                .catch(handleBackendError)
        })
    }

    getOpenDeviations(filters: OrganizationUnit[]) {
        return retryablePromise(() => {
            return axios
                .post<Deviation[]>(dviewApiUrl('open'), filters)
                .then(result => {
                    //This is to ensure that the dates (received as strings from API) are parsed to JS dates
                    result.data.forEach(d => (d.createdDate = new Date(d.createdDate)))
                    result.data.forEach(d => (d.startDate = new Date(d.startDate)))
                    result.data.forEach(d => (d.discoveryDate = new Date(d.discoveryDate)))
                    return result.data
                })
                .catch(handleBackendError)
        })
    }

    getHocRatedDeviations(filters: OrganizationUnit[]) {
        return retryablePromise(() => {
            return axios
                .post<Deviation[]>(dviewApiUrl('hocratings'), filters)
                .then(result => {
                    //This is to ensure that the dates (received as strings from API) are parsed to JS dates
                    result.data.forEach(d => (d.createdDate = new Date(d.createdDate)))
                    result.data.forEach(d => (d.startDate = new Date(d.startDate)))
                    result.data.forEach(d => (d.discoveryDate = new Date(d.discoveryDate)))
                    result.data.forEach(d => (d.approvedDate = new Date(d.approvedDate!)))
                    result.data.forEach(d => {
                        if (d.latestRatingDate) {
                            d.latestRatingDate = new Date(d.latestRatingDate!)
                        }
                    })
                    return result.data
                })
                .catch(handleBackendError)
        })
    }

    getNovoGlowLastUpdated() {
        return retryablePromise(() => {
            return axios
                .get<string>(dviewApiUrl('novoglowupdate'))
                .then(result => (result.data != null ? new Date(result.data) : null!))
                .catch(handleBackendError)
        })
    }

    getDeviationSummary(period: DeviationSummaryPeriod) {
        return retryablePromise(() => {
            return axios
                .get<DeviationSummary>(dviewApiUrl('deviationssummary'), { params: { period } })
                .then(result => result.data)
                .catch(handleBackendError)
        })
    }
}
