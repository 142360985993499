import { CustomPushNotificationRequest, CustomPushNotificationResponse, PushNotificationsService } from '../pushnotifications-api'
import { fakeEndpointDelay } from './backend-mock-util'

export class PushnotificationsServiceMock implements PushNotificationsService {
    async registerPushDevice(deviceToken: string) {
        await fakeEndpointDelay('fast')
        const localStorageResponse = updateLocalStorageDeviceRegistration(deviceToken)

        if (localStorageResponse) {
            console.log(`Device ${localStorageResponse} was successfully registered`)
        } else {
            console.error('Local storage item \'devicetoken\' does not exist')
        }

        return localStorageResponse
    }

    async unregisterPushDevice() {
        await fakeEndpointDelay('fast')
        const localStorageResponse = updateLocalStorageDeviceRegistration()

        if (localStorageResponse) {
            console.log(`Device ${localStorageResponse} was successfully unregistered`)
        } else {
            console.error('Local storage item \'devicetoken\' does not exist')
        }

        return localStorageResponse
    }

    async sendCustomNotification(request: CustomPushNotificationRequest): Promise<CustomPushNotificationResponse> {
        await fakeEndpointDelay('normal')
        return {
            numberOfNotificationsFailed: 0,
            numberOfNotificationsSent: 0
        }
    }
}

function updateLocalStorageDeviceRegistration(deviceToken?: string) {
    const saved = localStorage.getItem('devicetoken')

    if (!saved && !deviceToken) {
        return ''
    }

    if (!deviceToken) {
        localStorage.removeItem('devicetoken')
        return saved || ''
    }

    if (!saved) {
        localStorage.setItem('devicetoken', deviceToken)
    }

    return deviceToken
}
