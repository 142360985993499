import { OrganizationUnit } from './organization';

export interface UserPreferences {
    userId: string
    userOnboarded: boolean
    notifications: NotificationSettings
    orgFilter: OrganizationUnit[]
}

export interface NotificationSettings {
    mode: NotificationMode
    newDeviations: boolean
    newHocRatings: boolean
}

export enum NotificationMode {
    None = 0,
    Daily = 1
}
