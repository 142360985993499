import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useAuth } from '@dview/logic/use-auth'
import { appConfig } from '@dview/core/appconfig'
import { isUserDeveloper } from '@dview/shared/helper'

export function DeveloperGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const { user, isAuthenticated } = useAuth()

    if (appConfig.environment.production || !isAuthenticated) {
        return next(false)
    }

    if (isUserDeveloper(user.value)) {
        return next()
    }
}