export interface DViewApplicationConfig {
    baseUrl: string
    msalClientId: string
    msalBackendScope: string
    msalNativeRedirectUrl: string
    azureAppInsightsKey: string
    mock: {
        backend: boolean
        backendFakeDelay: boolean
        login: boolean
    },
    environment: {
        name: EnvironmentName
        sandbox: boolean
        production: boolean
    },
    version: string
}

function createTypesafeConfigObject() {
    const baseUrl = process.env.VUE_APP_API_BASE_URL ?? ''
    const msalClientId = process.env.VUE_APP_MSAL_CLIENTID ?? ''
    const msalBackendScope = process.env.VUE_APP_MSAL_BACKEND_SCOPE ?? ''
    const msalNativeRedirectUrl = process.env.VUE_APP_MSAL_NATIVE_REDIRECT ?? ''
    const mockedBackend = process.env.VUE_APP_MOCK_BACKEND === 'true'
    const mockedBackendFakeDelay = process.env.VUE_APP_MOCK_BACKEND_FAKE_DELAY === 'true'
    const mockedLogin = process.env.VUE_APP_MOCK_BACKEND_FAKE_LOGIN === 'true'
    const azureAppInsightsKey = process.env.VUE_APP_AZURE_APP_INSIGHTS_KEY ?? ''
    const environment = process.env.VUE_APP_ENVIRONMENT ?? 'unknown environment'
    const version = process.env.VUE_APP_VERSION ?? ''

    const isSandboxEnvironment = isRunningSandboxEnvironment() || isLocalhostMockedSandbox()
    const isProductionEnvironment = environment === 'production'

    const config: Readonly<DViewApplicationConfig> = {
        baseUrl,
        msalClientId,
        msalBackendScope,
        msalNativeRedirectUrl,
        azureAppInsightsKey,
        mock: {
            backend: mockedBackend,
            backendFakeDelay: mockedBackendFakeDelay,
            login: mockedLogin,
        },
        environment: {
            name: environment,
            sandbox: isSandboxEnvironment,
            production: isProductionEnvironment
        },
        version
    }

    return config
}

function isRunningSandboxEnvironment() {
    return process.env.NODE_ENV === 'development' && 
           process.env.VUE_APP_ENVIRONMENT === 'sandbox' ||
           window.location.hostname.startsWith('dviewsandbox')
}

function isLocalhostMockedSandbox() {
    return process.env.NODE_ENV === 'development' &&
           process.env.VUE_APP_ENVIRONMENT === 'sandbox' ||
           (process.env.VUE_APP_MOCK_BACKEND === 'true' &&
           window.location.hostname === 'localhost')
}

export const appConfig = createTypesafeConfigObject()

type EnvironmentName = 'localhost' | 'sandbox' | 'tst' | 'dev' | 'production'
