import { OrganizationStructure, OrganizationUnit, OrganizationUnitType } from '@dview/shared/models'
import { OrganizationService } from '../organization-api'
import { fakeEndpointDelay } from './backend-mock-util'

export class OrganizationServiceMock implements OrganizationService {

    async getOrganizationStructure() {
        const orgStructure: OrganizationStructure = {
            svps: dummySvp,
            cvps: dummyCvp,
            vps: dummyVp,
            depts: dummyDepartment,
            teams: dummyTeam
        }
        await fakeEndpointDelay('normal')
        return Promise.resolve(orgStructure)
    }
    
}

const dummySvp: OrganizationUnit[] = [
    { type: OrganizationUnitType.Svp, name: 'Biopharm (LoB)', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'Biopharm QA', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'Biopharmaceuticals', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'CIO Office', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'CMC Development', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'CMD Development QA', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'Corporate Costs', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'Corporate Facilities', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'CSCA LoB', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'D&S QA', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'Devices & Delivery Solutions', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'DFP QA', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'Diabetes API', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'Diabetes FP', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'Digital Incubator and Innovation', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'GITO', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'Global Development', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'HQ Business Partners', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'IO Central', relations: {} },
    { type: OrganizationUnitType.Svp, name: 'Menorail Engagement', relations: {} },
]

const dummyCvp: OrganizationUnit[] = [
    { type: OrganizationUnitType.Cvp, name: 'Bio API', relations: rel('Biopharm (LoB)', 'svp') },
    { type: OrganizationUnitType.Cvp, name: 'Bio BS', relations: rel('Biopharm (LoB)', 'svp') },
    { type: OrganizationUnitType.Cvp, name: 'Bio FP GE', relations: rel('Biopharm QA', 'svp') },
    { type: OrganizationUnitType.Cvp, name: 'Bio FP GA', relations: rel('Biopharm QA', 'svp') },
    { type: OrganizationUnitType.Cvp, name: 'Bio FP MA&VA', relations: rel('Biopharm QA', 'svp') },
    { type: OrganizationUnitType.Cvp, name: 'Bio Man', relations: rel('Biopharmaceuticals', 'svp') },
    { type: OrganizationUnitType.Cvp, name: 'Bio MDEV', relations: rel('Biopharmaceuticals', 'svp') },
    { type: OrganizationUnitType.Cvp, name: 'Bio New Hampshire US', relations: rel('CIO Office', 'svp') },
    { type: OrganizationUnitType.Cvp, name: 'BIO QC', relations: rel('CIO Office', 'svp') },
]

const dummyVp: OrganizationUnit[] = [
    { type: OrganizationUnitType.Vp, name: 'VP1', relations: rel('Bio API', 'cvp') },
    { type: OrganizationUnitType.Vp, name: 'VP2', relations: rel('Bio API', 'cvp') },
    { type: OrganizationUnitType.Vp, name: 'VP3', relations: rel('Bio BS', 'cvp') },
    { type: OrganizationUnitType.Vp, name: 'VP4', relations: rel('Bio FP GE', 'cvp') },
    { type: OrganizationUnitType.Vp, name: 'VP5', relations: rel('Bio FP GA', 'cvp') },
    { type: OrganizationUnitType.Vp, name: 'VP6', relations: rel('Bio FP MA&VA', 'cvp') },
    { type: OrganizationUnitType.Vp, name: 'VP7', relations: rel('Bio Man', 'cvp') },
    { type: OrganizationUnitType.Vp, name: 'VP8', relations: rel('Bio MDEV', 'cvp') },
    { type: OrganizationUnitType.Vp, name: 'VP9', relations: rel('Bio New Hampshire US', 'cvp') },
    { type: OrganizationUnitType.Vp, name: 'VP10', relations: rel('BIO QC', 'cvp') },
]

const dummyDepartment: OrganizationUnit[] = [
    { type: OrganizationUnitType.Department, name: 'Department1', relations: rel('VP1', 'vp') },
    { type: OrganizationUnitType.Department, name: 'Department2', relations: rel('VP2', 'vp') },
    { type: OrganizationUnitType.Department, name: 'Department3', relations: rel('VP3', 'vp') },
    { type: OrganizationUnitType.Department, name: 'Department4', relations: rel('VP4', 'vp') },
    { type: OrganizationUnitType.Department, name: 'Department5', relations: rel('VP5', 'vp') },
    { type: OrganizationUnitType.Department, name: 'Department6', relations: rel('VP6', 'vp') },
    { type: OrganizationUnitType.Department, name: 'Department7', relations: rel('VP7', 'vp') },
    { type: OrganizationUnitType.Department, name: 'Department8', relations: rel('VP8', 'vp') },
    { type: OrganizationUnitType.Department, name: 'Department9', relations: rel('VP9', 'vp') },
    { type: OrganizationUnitType.Department, name: 'Department10', relations: rel('VP10', 'vp') },
]

const dummyTeam: OrganizationUnit[] = [
    { type: OrganizationUnitType.Team, name: 'Team1', relations: rel('Department1', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team2', relations: rel('Department1', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team3', relations: rel('Department1', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team4', relations: rel('Department1', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team5', relations: rel('Department2', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team6', relations: rel('Department2', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team7', relations: rel('Department3', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team8', relations: rel('Department3', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team9', relations: rel('Department4', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team10', relations: rel('Department5', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team11', relations: rel('Department5', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team12', relations: rel('Department6', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team13', relations: rel('Department7', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team14', relations: rel('Department8', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team15', relations: rel('Department8', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team16', relations: rel('Department8', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team17', relations: rel('Department8', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team18', relations: rel('Department8', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team19', relations: rel('Department9', 'department') },
    { type: OrganizationUnitType.Team, name: 'Team20', relations: rel('Department10', 'department') },
]

function rel(parentName: string, parentType: 'svp' | 'cvp' | 'vp' | 'department') {
    let parentItem: OrganizationUnit = {} as OrganizationUnit

    if (parentType === 'svp') {
        parentItem = dummySvp.find(x => x.name === parentName)!
    }
    if (parentType === 'cvp') {
        parentItem = dummyCvp.find(x => x.name === parentName)!
    }
    if (parentType === 'vp') {
        parentItem = dummyVp.find(x => x.name === parentName)!
    }
    if (parentType === 'department') {
        parentItem = dummyDepartment.find(x => x.name === parentName)!
    }

    return {...parentItem.relations, ...{ [parentType]: parentName }}
}