import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { ActionPerformed, PushNotifications } from '@capacitor/push-notifications'
import { SplashScreen } from '@capacitor/splash-screen'
import { appConfig } from '@dview/core'
import { AppLauncher } from '@capacitor/app-launcher'
import { useDeferredPromise } from '@dview/shared/util'

let splashScreenPromise: Promise<void>

let nativePauseResumeListenerRegistered = false
const appPauseListeners: AppPauseCallbackFunction[] = []
const appResumeListeners: AppResumeCallbackFunction[] = []

/**
 * Abstracts away access to native plugin functionality, and ensures that proper fallback behavior
 * is implemented when called in SPA webapp mode which does not support the particular functionality.
 * 
 * Code in here should NOT contain any DView business logic, it should be entirely focused on providing
 * a unified and safe way to access native functionality, whilst providing fallbacks for web platform.
 */
export function useNative() {

    /**
     * Returns the current native app version and build number.
     * 
     * - On native, this will fetch bundle version from the iOS app
     * - On web, this will fetch version number from package.json
     * 
     * @returns 
     */
    async function getAppVersion() {
        const appVersion = {
            version: '',
            build: ''
        }

        if (isWebPlatform()) {
            appVersion.version = appConfig.version
            appVersion.build = `webspa ${appConfig.environment.name}`
        } else {
            const appInfo = await App.getInfo()
            appVersion.version = appInfo.version

            // NN App Store resigning process complains if CFBundleVersion is not a fully qualified version,
            // as opposed to just a build number, like we use when deploying to TestFlight etc...
            if (appInfo.build.includes('.')) {
                const buildNumber = appInfo.build.split('.').pop()! // take the last build number part, eg. "1.0.1.24" will yield "24"
                appVersion.build = `build ${buildNumber} ${appConfig.environment.name}`    
            } else {
                appVersion.build = `build ${appInfo.build} ${appConfig.environment.name}`
            }
        }
        
        return appVersion
    }

    /**
     * Hides the native splash screen once Vue application is booted up and ready.
     * A 1000ms delay is added to ensure a minimum splash screen display time, to
     * avoid "blinking behavior" in case app loads "too fast".
     * 
     * Since we initially hide the status bar during splash screen, we explicitly 
     * need to show it again in tandem with hiding the splash screen.
     */
    function hideSplashScreen(delay = 500) {
        if (isWebPlatform()) {
            return Promise.resolve()
        }

        if (splashScreenPromise) {
            return splashScreenPromise
        }

        splashScreenPromise = new Promise<void>(resolve => {
            setTimeout(() => {
                SplashScreen.hide({ fadeOutDuration: 500 })

                // 2021.05.19:
                // Currently leaving statusbar hidden, as due to our current login flow where we open
                // an external safari browser to authenticate, causes a "safari backlink" to appear in
                // the top left corner of the statusbar.
                // StatusBar.show({ animation: Animation.Fade })

                resolve()
            }, delay)
        })

        return splashScreenPromise
    }

    /**
     * Adds a listener in order to redirect the user to custom pages, given the user
     * is comming from tapping the app's push notification (plus any extra conditions)
     */
    async function onPushNotificationAction(callback: (notification: NotificationActionPerformed) => void) {
        if (isWebPlatform()) {
            return
        }

        await PushNotifications.addListener('pushNotificationActionPerformed', (notification: NotificationActionPerformed) => {
            callback(notification)
        })
    }

    /**
     * Checks if user has allowed permissions for some native functionality.
     * 
     * @param permission 
     * @returns 
     */
    async function hasAllowedPermission(permission: NativePermission) {
        if (isWebPlatform()) {
            return false
        }

        if (permission === NativePermission.PushNotifications) {
            const permissions = await PushNotifications.checkPermissions()
            return permissions.receive === 'granted'
        }

        return false
    }

    /**
     * Checks if user has denied permissions for some native functionality.
     * 
     * @param permission 
     * @returns 
     */
    async function hasDeniedPermission(permission: NativePermission) {
        if (isWebPlatform()) {
            return false
        }

        if (permission === NativePermission.PushNotifications) {
            const permissions = await PushNotifications.checkPermissions()
            return permissions.receive === 'denied'
        }

        return false
    }

    /**
     * Displays a native iOS popup asking the end user whether he/she trusts the app to send him 
     * push notifications or not (Allow / Don't allow as options presented in the popup)
     * 
     * @returns the result denoting the end-user's answer to the frontend app
     * requesting permission for receiving push notifications (including the device token,
     * needed for subscribing to push notifications)
     */
    async function registerForPushNotificationsWithApple(): Promise<PushRegistrationStatus> {
        const { deferred } = useDeferredPromise<PushRegistrationStatus>()

        const deviceRegistrationListener = await PushNotifications.addListener('registration', token => {
            console.info('Device registered for push notifications with Apple APNS.')
            deferred.resolve({ state: 'registered', deviceToken: token.value })
            removeRegistrationListeners()
        })

        const deviceRegistrationErrorListener = await PushNotifications.addListener('registrationError', error => {
            console.error('Error occured during push notification registration.', error)
            deferred.resolve({ state: 'registration-error', errorMessage: error })
            removeRegistrationListeners()
        })

        const permission = await PushNotifications.requestPermissions()
        if (permission.receive !== 'denied') {
            PushNotifications.register() // this will eventually trigger the "registration" or "registrationError" listener callbacks above
        } else {
            deferred.resolve({ state: 'permission-error', errorMessage: 'User denied push permissions' })
        }

        function removeRegistrationListeners() {
            deviceRegistrationListener.remove()
            deviceRegistrationErrorListener.remove()
        }
        
        return deferred.promise
    }

    /**
     * Checks if app may open another native application and returns a boolean accordingly.
     * 
     * @param appUrlScheme 
     * @returns 
     */
    async function canOpenNativeApp(appUrlScheme: string) {
        if (isWebPlatform()) {
            return false
        }

        // special case for app settings, which needs bundle id postfixed
        if (appUrlScheme === AppleSystemAppSchemes.AppSettings) {
            const appInfo = await App.getInfo()
            appUrlScheme = appUrlScheme + appInfo.id
        }

        const { value } =  await AppLauncher.canOpenUrl({ url: appUrlScheme })
        
        return value
    }

    /**
     * Opens an instance of another native application according to the given url scheme.
     * 
     * @param appUrlScheme 
     */
    async function openNativeApp(appUrlScheme: string) {
        if (isWebPlatform()) {
            return false
        }

        // special case for app settings, which needs bundle id postfixed
        if (appUrlScheme === AppleSystemAppSchemes.AppSettings) {
            const appInfo = await App.getInfo()
            appUrlScheme = appUrlScheme + appInfo.id
        }

        const { completed } = await AppLauncher.openUrl({ url: appUrlScheme })

        return completed
    }

    /**
     * Register a function to be executed whenever the app is paused.
     * @param callback 
     */
    function onAppPause(callback: AppPauseCallbackFunction) {
        if (isWebPlatform()) {
            return NOOP
        }

        if (!nativePauseResumeListenerRegistered) {
            registerNativePauseResumeListener()
        }

        appPauseListeners.push(callback)

        // return handle to unregister listener
        return createUnregisterListenerHandle(callback, appPauseListeners)
    }

    /**
     * Checks if the app is currently in the background of the user's iPhone.
     * 
     * @returns 
     */
    async function isAppPaused() {
        const appState = await App.getState()
        return !appState.isActive
    }

    /**
     * Register a function to be executed whenever the app is resumed.
     * 
     * @param callback 
     */
    function onAppResume(callback: AppResumeCallbackFunction) {
        if (isWebPlatform()) {
            return NOOP
        }

        if (!nativePauseResumeListenerRegistered) {
            registerNativePauseResumeListener()
        }

        appResumeListeners.push(callback)

        // return handle to unregister listener
        return createUnregisterListenerHandle(callback, appResumeListeners)
    }

    /**
     * Adds a native listener to listen for whenever the app goes into the background (out of focus),
     * and when it returns back into foreground (in focus).
     * 
     * @returns 
     */
    function registerNativePauseResumeListener() {
        if (isWebPlatform()) {
            return
        }

        if (nativePauseResumeListenerRegistered) {
            return
        }

        App.addListener('appStateChange', event => {
            if (event.isActive) {
                appResumeListeners.forEach(listener => listener())
            } else {
                appPauseListeners.forEach(listener => listener())
            }
        })

        nativePauseResumeListenerRegistered = true
    }

    function isWebPlatform() {
        return !Capacitor.isNativePlatform()
    }


    return {
        getAppVersion,
        hideSplashScreen,
        isAppPaused,
        onAppPause,
        onAppResume,
        canOpenNativeApp,
        openNativeApp,
        hasAllowedPermission,
        hasDeniedPermission,
        onPushNotificationAction,
        registerForPushNotificationsWithApple
    }
}

const NOOP = () => {}

function createUnregisterListenerHandle(listenerFn: Function, listeners: any[]) {
    return () => {
        const index = listeners.indexOf(listenerFn)
        if (index !== -1) {
            listeners.splice(index, 1)
        }
    }
}

interface CustomPushNotificationSchema {
    data: {
        aps: {
            alert: string | {
                title?: string
                subtitle?: string
                body?: string
            }
            newDeviations?: string
            newHocRatings?: string
        }
    }
    id: string
}

export interface NotificationActionPerformed extends ActionPerformed {
    notification: CustomPushNotificationSchema
}

export interface PushRegistrationStatus {
    state: 'registered' | 'registration-error' | 'permission-error'
    deviceToken?: string
    errorMessage?: string
}

export enum AppleSystemAppSchemes {
    AppSettings = 'app-settings:'
}

export enum NativePermission {
    PushNotifications
}

type AppPauseCallbackFunction = () => void
type AppResumeCallbackFunction = () => void