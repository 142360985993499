import { Capacitor } from '@capacitor/core'
import { appConfig } from '@dview/core/appconfig'
import { useAuthMock } from './auth/use-auth-mock'
import { useAuthNative } from './auth/use-auth-native'
import { useAuthWeb } from './auth/use-auth-web'

const authImplementation = determineAuthenticationImplementation()

function determineAuthenticationImplementation() {
    const mockLogin = appConfig.mock.backend || appConfig.environment.sandbox

    if (mockLogin) {
        return useAuthMock
    } else {
        if (Capacitor.isNativePlatform()) {
            return useAuthNative
        } else {
            return useAuthWeb
        }
    }
}

export function useAuth() {
    return authImplementation()
}

export * from './auth/auth.interface'