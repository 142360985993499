import { DeviationsServiceApi, OrganizationServiceApi, UserPreferencesServiceApi, DViewBackendError, UserPreferencesService, OrganizationService, DeviationsService } from '@dview/shared/backend'
import { DeviationsServiceMock, OrganizationServiceMock, UserpreferencesServiceMock } from '@dview/shared/backend/mock'
import { PushnotificationsServiceMock } from '@dview/shared/backend/mock'
import { PushNotificationsService, PushNotificationsServiceApi } from '@dview/shared/backend/pushnotifications-api'
import { appConfig } from '../core'

const mocked = appConfig.mock.backend

const deviationsService: DeviationsService = mocked ? new DeviationsServiceMock() : new DeviationsServiceApi()
const organizationService: OrganizationService = mocked ? new OrganizationServiceMock() : new OrganizationServiceApi()
const pushNotificationService: PushNotificationsService = mocked ? new PushnotificationsServiceMock() : new PushNotificationsServiceApi()
const userPreferencesService: UserPreferencesService = mocked ? new UserpreferencesServiceMock() : new UserPreferencesServiceApi()

export function useBackend() {
    return {
        deviationsService,
        organizationService,
        pushNotificationService,
        userPreferencesService,
    }
}

export { DViewBackendError }