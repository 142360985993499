import axios from 'axios'
import { OrganizationStructure } from '@dview/shared/models'
import { handleBackendError } from './errorhandling'
import { dviewApiUrl } from './util'

export interface OrganizationService {
    getOrganizationStructure(): Promise<OrganizationStructure>
}

export class OrganizationServiceApi implements OrganizationService {

    getOrganizationStructure() {
        return axios
            .get<OrganizationStructure>(dviewApiUrl('organization'))
            .then(result => result.data)
            .catch(handleBackendError)
    }

}
