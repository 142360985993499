import axios from 'axios'
import { OrganizationUnit } from '../models'
import { NotificationSettings, UserPreferences } from '../models/userpreferences'
import { handleBackendError } from './errorhandling'
import { dviewApiUrl } from './util'

export interface UserPreferencesService {
    getUserPreferences(): Promise<UserPreferences>
    updateNotificationSettings(notificationSettings: NotificationSettings): Promise<void>
    updateOrganizationFilter(filter: OrganizationUnit[]): Promise<void>
}

export class UserPreferencesServiceApi implements UserPreferencesService {
    
    getUserPreferences(): Promise<UserPreferences> {
        return axios
            .get<UserPreferences>(dviewApiUrl('getuserpreferences'))
            .then(result => result.data)
            .catch(handleBackendError)
    }

    updateNotificationSettings(notificationSettings: NotificationSettings) {
        const params = {
            notificationSettings
        }
        return axios
            .post<any, void>(dviewApiUrl('updatenotifications'), null, { params })
            .catch(handleBackendError)
    }

    updateOrganizationFilter(filter: OrganizationUnit[]) {
        return axios
            .post<any, void>(dviewApiUrl('updateorganizationfilter'), filter)
            .catch(handleBackendError)
    }
}
