import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useUserPreferences, useAuth, useNative } from '@dview/logic'
import { useDViewGlobalRouter } from '@dview/core/use-dview-global-router'

let initialized = false

export async function AppInitedGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) { 
    if (isSpecialUniversalLinkAuthRedirect(to)) {
        return next(false)
    }

    if (isExemptFromRouteGuard(to) || initialized) {
        return next()
    }
    

    const authenticated = await isUserAuthenticated()
    if (!authenticated) {
        const router = useDViewGlobalRouter()
        router.pushAppEntryRoute(to)
        return next({ name: 'Login' })
    }

    // Logic below will only be executed once!
    //
    // Once user preferences has been loaded and initialized variable set to true,
    // the app init guard has done it's job, and from then on just delegates to
    // whichever next route guard might be the chain.

    const userPrefsLoaded = await loadUserPreferences()
    hideSplashScreen()

    if (userPrefsLoaded) {
        initialized = true
        return next()
    } else {
        console.error('Error loading user preferences.')
        return next({ name: 'Error', replace: true }) 
    }
}

function isExemptFromRouteGuard(route: RouteLocationNormalized) {
    return route.meta.exemptFromRouteGuards === true
}

/**
 * A special check to avoid the SPA application accidentally being executed on the http side (in the system browser) 
 * during a universal link callback to the native app. This could potentially result in two attempted logins.
 * 
 * @param route 
 * @returns 
 */
function isSpecialUniversalLinkAuthRedirect(route: RouteLocationNormalized) {
    return route.path.includes('/auth')
}

async function isUserAuthenticated() {
    const { isAuthenticated } = useAuth()
    
    if (!isAuthenticated()) {
        // ideally refresh sessionwork work on app start if we can configure MSAL to store refresh credentials correctly
        /*const status = await refreshSession()
        if (status === RefreshSessionStatus.Expired) {
            return false
        }*/
    }

    return isAuthenticated()
}

async function loadUserPreferences() {
    const { load } = useUserPreferences()
    try {
        await load()
        return true
    }
    catch {
        return false
    }
}

function hideSplashScreen() {
    const { hideSplashScreen } = useNative()
    return hideSplashScreen()
}
