
import { defineComponent, nextTick, reactive, Ref, ref, toRefs, watch } from 'vue'
import { useRouter } from 'vue-router'
import { Capacitor } from '@capacitor/core'
import { IonApp, IonRouterOutlet, IonMenu } from '@ionic/vue'
import { businessOutline, notificationsOutline, logOutOutline, chatboxEllipsesOutline, buildOutline } from 'ionicons/icons'
import { setupAxiosAuthInterceptor } from '@dview/shared/backend/axios-interceptors'
import { appConfig, useDViewGlobalRouter } from '@dview/core'
import { useAuth, useNative, useDeviations, NotificationActionPerformed, useDeviationSummary } from '@dview/logic'
import { isUserDeveloper } from '@dview/shared/helper'
import { useAppInsights } from '@dview/shared/util'
import { DeviationType } from './shared/models'

export default defineComponent({
    components: {
        IonApp,
        IonRouterOutlet,
        IonMenu,
    },
    setup() {
        const { user } = useAuth()
        const { menuItems, developerItem } = useMenuItems()
        const { version, build, showBuildInfo } = useAppVersion()
        const { onPushNotificationAction } = useNative()

        let developerToggleCount = 0

        setupAxiosAuthInterceptor()
        configureGlobalRouter()
        setupAutomaticDeviationDataRefresh()
        onPushNotificationAction(handlePushNotificationEvents)
        trackAppVersion(version)

        function toggleDeveloperMenuInProduction() {
            developerToggleCount += 1
            if (developerToggleCount >= 7) {
                if (isUserDeveloper(user.value)) {
                    developerItem.hidden = false
                }
            }
        }

        return {
            menuItems,
            user,
            version, 
            build, 
            showBuildInfo,
            toggleDeveloperMenuInProduction
        }
    },
})

function useMenuItems() {
    const { user } = useAuth()
    const menuItems = ref<MenuItem[]>([])

    /**
     * Setting up sidebar menu on App.vue for now, will probably move it somewhere else
     * as the app navigation structure grows.
     *
     * It doesn't really make sense that the sidebar i potentially accessible on the login
     * or onboarding pages, but should suffice for now.
     */
    menuItems.value.push({
        name: 'Select Areas',
        path: '/settings/filter',
        icon: businessOutline,
    })

    menuItems.value.push({
        name: 'Notification Settings',
        path: '/settings/notification',
        icon: notificationsOutline,
    })

    menuItems.value.push({
        name: 'Feedback & Support',
        path: '/support',
        icon: chatboxEllipsesOutline,
    })

    const developerItem = reactive({
        name: 'Developer',
        path: '/developer',
        icon: buildOutline,
        hidden: true,
    })

    menuItems.value.push(developerItem)

    // automatically show developer menu item in non-prod environments.
    // to display this menu item in prod environment, developer must tap seven (7) times 
    /// in the DView build area located at the bottom of the sidebar.
    if (!appConfig.environment.production) {
        // when login comples, the user object will be populated, so then we can determine whether to show developer page or not
        const unsubscribeWatch = watch(user, user => {
            if (user) {
                if (isUserDeveloper(user)) {
                    developerItem.hidden = false
                }
                nextTick(() => unsubscribeWatch())
            }
        }, { immediate: true })
    }

    menuItems.value.push({
        name: 'Logout',
        path: '/login',
        icon: logOutOutline,
        disabled: true,
    })

    return {
        menuItems, developerItem
    }
}

function useAppVersion() {
    const appVersion = reactive({
        version: '',
        build: '',
        showBuildInfo: !appConfig.environment.production
    })

    async function fetchAppVersion() {
        const { getAppVersion } = useNative()

        const versioningInfo = await getAppVersion()
        appVersion.version = versioningInfo.version
        appVersion.build = versioningInfo.build
    }

    fetchAppVersion()

    return {
        ...toRefs(appVersion)
    }
}

/**
 * !! NOT MEANT TO REPLACE VUE-ROUTER !!
 * 
 * Configures a globally accessible router. Only to be used for special async callback situations, 
 * where the vue-router is not accessible, eg. native Capacitor listeners.
 * 
 * !! NOT MEANT TO REPLACE VUE-ROUTER !!
 */
function configureGlobalRouter() {
    const router = useRouter()
    const globalRouter = useDViewGlobalRouter() as any
    
    // special "hidden" configure method
    globalRouter.configure(router)
}

/**
 * Whenever the user resumes the app on his phone, it might be time to refresh the last loaded
 * deviations data.
 * 
 * Native only.
 */
function setupAutomaticDeviationDataRefresh() {
    const { onAppResume } = useNative()
    const { refresh: refreshOpenDeviations } = useDeviations(DeviationType.Open)
    const { refresh: refreshHocRatedDeviations } = useDeviations(DeviationType.HoC)
    const { refresh: refreshDeviationSummary } = useDeviationSummary()

    onAppResume(() => {
        refreshOpenDeviations()
        refreshHocRatedDeviations()
        refreshDeviationSummary()
    })
}

function handlePushNotificationEvents(notification: NotificationActionPerformed) {
    const { trackEvent } = useAppInsights()
    const dviewRouter = useDViewGlobalRouter()

    trackEvent({
        name: `User opened app via Notification`,
        properties: {
            'directed-to': 'overview',
        }
    })
    
    dviewRouter.push('/deviations/overview')
}

/**
 * Tracks what version (and platform) the user is running when starting the app.
 */
function trackAppVersion(appVersion: Ref<string>) {
    const { trackEvent } = useAppInsights()
    const { user } = useAuth()

    // wait with tracking until we have confirmed authenticated user data in hand
    const unsubscribeWatch = watch([user, appVersion], ([user, version]) => {
        if (user != null && version?.length > 0) {
            trackEvent({ name: 'App version', properties: {
                platform: Capacitor.isNativePlatform() ? 'ios' : 'web',
                version: version
            }})
            
            nextTick(() => unsubscribeWatch())
        }
    }, { immediate: true })
}

interface MenuItem {
    name: string
    path: string
    icon: string
    disabled?: boolean
    hidden?: Ref<boolean>
}
