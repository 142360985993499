import { Ref } from 'vue'

export interface DViewAuthentication {
    user: Ref<AuthenticatedUser>

    getAccessToken(): string

    login(): Promise<LoginStatus>
    logout(): Promise<void>
    refreshSession(): Promise<RefreshSessionStatus>

    isAuthenticated(): boolean
    isAccessTokenAvailable(): boolean
    isAccessTokenExpired(): boolean
}

export interface AuthenticatedUser {
    name: string
    username: string
}

export enum LoginStatus {
    Success,
    Failed
}

export enum RefreshSessionStatus {
    Success,
    Expired
}