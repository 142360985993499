import { appConfig } from '@dview/core'

export function dviewApiUrl(endpoint: string) {
    if (!endpoint.startsWith('api/')) {
        endpoint = 'api/' + endpoint
    }

    let finalUrl = ''

    if (appConfig.baseUrl && appConfig.baseUrl.length > 0) {
        finalUrl += `${appConfig.baseUrl}`
    }

    finalUrl += endpoint

    return finalUrl
}

