import { computed, reactive, ref, watch, watchEffect } from 'vue'
import _ from 'lodash'
import { OrganizationStructure, OrganizationUnit, OrganizationUnitType } from '@dview/shared/models'
import { useUserPreferences } from './use-userpreferences'
import { useBackend } from './use-backend'

const DEFAULT_FILTER_ITEM = () => ({
    selected: [],
    items: [],
})

const state = reactive<OrganizationAreasState>({
    svp: DEFAULT_FILTER_ITEM(),
    cvp: DEFAULT_FILTER_ITEM(),
    vp: DEFAULT_FILTER_ITEM(),
    department: DEFAULT_FILTER_ITEM(),
    team: DEFAULT_FILTER_ITEM(),
})

const dataSource: DataSourceState = {
    status: 'not-loaded',
    data: null!
}

const activeAreasFilter = ref<OrganizationUnit[]>(null!)
const savedFilterAppliedToDropdowns = ref(false)

export function useOrganizationAreas() {
    const { organizationService } = useBackend()
    const { setOrganizationFilter, preferences } = useUserPreferences()

    async function init() {
        if (dataSource.status === 'not-loaded') {
            activeAreasFilter.value = convertUserPreferencesToActiveFilter(preferences.orgFilter)
            await loadData()
            applySavedFilterToDropdowns()
        }
    }

    /**
     * Organizational units at the lowest level of organization hierarchy has highest priority
     * in terms of determining what becomes the active filter to be sent to backend.
     */
    function convertUserPreferencesToActiveFilter(filters: Readonly<OrganizationUnit[]>) {
        if (!filters) {
            return []
        }

        const teams = filters.filter(filter => filter.type === OrganizationUnitType.Team)
        const departments = filters.filter(filter => filter.type === OrganizationUnitType.Department)
        const vp = filters.filter(filter => filter.type === OrganizationUnitType.Vp)
        const cvp = filters.filter(filter => filter.type === OrganizationUnitType.Cvp)
        const svp = filters.filter(filter => filter.type === OrganizationUnitType.Svp)

        if (teams.length) {
            return teams
        } else if (departments.length) {
            return departments
        } else if (vp.length) {
            return vp
        } else if (cvp.length) {
            return cvp
        } else {
            return svp
        }
    }

    async function loadData() {
        dataSource.status = 'loading'
        const rawData = await organizationService.getOrganizationStructure()
        const data = dataSource.data = mapToDropdownItems(rawData)
        state.svp.items = data.svps
        state.cvp.items = data.cvps
        state.vp.items = data.vps
        state.department.items = data.departments
        state.team.items = data.teams
        dataSource.status = 'loaded'
    }

    function mapToDropdownItems(data: OrganizationStructure) {
        const mapData = (orgUnits: OrganizationUnit[]) => {
            return orgUnits
                .map<DropdownItem>((orgUnit, index) => ({
                    key: index,
                    text: orgUnit.name,
                    value: orgUnit
                }))
                .sort((a, b) => a.text.localeCompare(b.text, 'en', { numeric: true }))
        }
    
        return {
            svps: mapData(data.svps),
            cvps: mapData(data.cvps),
            vps: mapData(data.vps),
            departments: mapData(data.depts),
            teams: mapData(data.teams)
        } as MappedOrganizationData
    }

    function applySavedFilterToDropdowns() {
        const savedFilters = preferences.orgFilter

        savedFilters.forEach(filter => {
            const item = findMatchingDropdownItem(filter)
            if (item.exists) {
                item.markSelected()
            }
        })

        savedFilterAppliedToDropdowns.value = true
    }

    /**
     * Match a saved filter to an actual loaded dropdown element.
     * If the user saved a filter a month ago, and for example one of the saved team filter
     * elements no longer exists, then that element can't and won't be displayed as selected.
     */
    function findMatchingDropdownItem(savedFilter: OrganizationUnit) {
        let matchingDropdownState: DropdownModel = null!
        switch (savedFilter.type) {
            case OrganizationUnitType.Svp: matchingDropdownState = state.svp; break;
            case OrganizationUnitType.Cvp: matchingDropdownState = state.cvp; break;
            case OrganizationUnitType.Vp: matchingDropdownState = state.vp; break;
            case OrganizationUnitType.Department: matchingDropdownState = state.department; break;
            case OrganizationUnitType.Team: matchingDropdownState = state.team; break;
        }

        const matchingItem = matchingDropdownState.items.find(item => {
            const orgUnit = item.value
            return orgUnit.name === savedFilter.name
                && orgUnit.type === savedFilter.type
                && !!orgUnit.relations === !!savedFilter.relations
                && orgUnit.relations.svp === savedFilter.relations.svp
                && orgUnit.relations.cvp === savedFilter.relations.cvp
                && orgUnit.relations.vp === savedFilter.relations.vp
                && orgUnit.relations.department === savedFilter.relations.department
        })

        return {
            exists: matchingItem != null,
            markSelected: () => { 
                if (matchingItem) {
                    // limitation / bug in reactive array, using array.push(...) does not trigger changes
                    // so using array concatenation instead.
                    const selectedValues = matchingDropdownState.selected
                    matchingDropdownState.selected = [...selectedValues, matchingItem.value]
                }
            }
        }
    }

    /**
     * Watchers for the various dropdowns that depend on changes in other dropdowns
     * to trigger filtering in their respective items.
     */
    watch(() => state.svp.selected, svp => {
        state.cvp.items = filterDropdown(dataSource.data.cvps, svp)
        state.cvp.selected = handlePreviousSelections(state.cvp)
    })

    watch(() => [state.svp.selected, state.cvp.selected], ([svp, cvp]) => {
        state.vp.items = filterDropdown(dataSource.data.vps, svp, cvp)
        state.vp.selected = handlePreviousSelections(state.vp)
    })

    watch(() => [state.svp.selected, state.cvp.selected, state.vp.selected], ([svp, cvp, vp]) => {
        state.department.items = filterDropdown(dataSource.data.departments, svp, cvp, vp)
        state.department.selected = handlePreviousSelections(state.department)
    })

    watch(() => [state.svp.selected, state.cvp.selected, state.vp.selected, state.department.selected], ([svp, cvp, vp, department]) => {
        state.team.items = filterDropdown(dataSource.data.teams, svp, cvp, vp, department)
        state.team.selected = handlePreviousSelections(state.team)
    })

    /**
     * Filter dropdown content according to selections in other dropdowns.
     */
    function filterDropdown(dataSource: DropdownItem[], svp: OrganizationUnit[] = null!, cvp: OrganizationUnit[]  = null!, vp: OrganizationUnit[]  = null!, department: OrganizationUnit[]  = null!) {
        return _.filter(dataSource, ({ value }) => {
            const { svp: svpParent, cvp: cvpParent, vp: vpParent, department: deptParent } = value.relations

            const matchSvp = svp == null || svp.length === 0 || _.findIndex(svp, ({ name }) => svpParent === name) >= 0
            const matchCvp = cvp == null || cvp.length === 0 || _.findIndex(cvp, ({ name }) => cvpParent === name) >= 0
            const matchVp = vp == null || vp.length === 0 || _.findIndex(vp, ({ name }) => vpParent === name) >= 0
            const matchDept = department == null || department.length === 0 || _.findIndex(department, ({ name }) => deptParent === name) >= 0

            return matchSvp && matchCvp && matchVp && matchDept
        })
    }

    /**
     * Remove previous dropdown selections that are no longer valid in the active filter condition.
     */
    function handlePreviousSelections(dropdownState: DropdownModel) {
        const previousSelectedItems = dropdownState.selected
        const newFilteredItems = dropdownState.items
        return _.filter(previousSelectedItems, selection => {
            return _.findIndex(newFilteredItems, item => {
                return selection === item.value
            }) >= 0
        }) as OrganizationUnit[]
    }

    /**
     * Clears all filters existing within the state, under `selected` property
     */
    function clearAreas() {
        Object.values(state).forEach(filter => filter.selected = [])
    }

    /**
     * The active filter represents the lowest possible level of organization unit.
     * Eg. if a svp, cvp, vp, department and a team are selected in dropdowns, only
     * the team organization unit will be regarded as the end result active filter.
     */
    const setActiveFilter = _.debounce((filter: OrganizationUnit[]) => {
        // don't cause outside reactivity change if new value is same as old, or saved filter is not loaded and to applied to dropdowns yet
        if (_.isEqual(activeAreasFilter.value, filter) || !savedFilterAppliedToDropdowns.value) {
            return
        }
        activeAreasFilter.value = filter
    }, 100)

    /**
     * Triggered when the user selects an item in a dropdown.
     * 
     * Organizational units at the lowest level of organization hierarchy has highest priority
     * in terms of determining what becomes the active filter to be sent to backend.
     */
    watchEffect(() => {
        if (state.team.selected.length) {
            setActiveFilter(state.team.selected)
        } else if (state.department.selected.length) {
            setActiveFilter(state.department.selected)
        } else if (state.vp.selected.length) {
            setActiveFilter(state.vp.selected)
        } else if (state.cvp.selected.length) {
            setActiveFilter(state.cvp.selected)
        } else {
            setActiveFilter(state.svp.selected)
        }
    })

    const activeAreaSelected = computed(() => {
        return activeAreasFilter.value != null && activeAreasFilter.value.length > 0
    })

    async function save() {
        const selectedFilters = [...state.svp.selected, ...state.cvp.selected, ...state.vp.selected, ...state.department.selected, ...state.team.selected]
        await setOrganizationFilter(selectedFilters)
    }

    init()

    return {
        activeAreasFilter,
        activeAreaSelected,
        clearAreas,
        areaFilterModel: state,
        save,
    }
}

export interface OrganizationAreasState {
    svp: DropdownModel
    cvp: DropdownModel
    vp: DropdownModel
    department: DropdownModel
    team: DropdownModel
}

interface DropdownModel {
    selected: OrganizationUnit[]
    items: DropdownItem[]
}

interface DropdownItem {
    key: number
    value: OrganizationUnit
    text: string
}

interface DataSourceState {
    status: 'not-loaded' | 'loading' | 'loaded'
    data: MappedOrganizationData
}

interface MappedOrganizationData {
    svps: DropdownItem[]
    cvps: DropdownItem[]
    vps: DropdownItem[]
    departments: DropdownItem[]
    teams: DropdownItem[]
}

