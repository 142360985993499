import { RouteLocationNormalized } from 'vue-router'
import { useAppInsights } from '@dview/shared/util'
import { useAuth } from '@dview/logic'

export function TrackPageViewNavHook(to: RouteLocationNormalized, from: RouteLocationNormalized) {
    const {
        trackPageView,
        timeElapsedOnPage,
        trackEvent,
        trackTimeElapsedOnPage,
        shouldPageBeTracked,
        trackMetric
    } = useAppInsights()
    const { user } = useAuth()
    const trackedPage = String(from.name)

    timeElapsedOnPage.value = Date.now()

    if (timeElapsedOnPage.value > 0 && shouldPageBeTracked(trackedPage)) {
        const { formattedPageName, formattedTimeElapsed, deltaTime } = trackTimeElapsedOnPage(trackedPage)
    
        trackEvent({
            name: 'Elapsed time in page',
            properties: {
                'app-paused': false,
                [`elapsed-${formattedPageName}`]: formattedTimeElapsed,
                'elapsed-comparable': deltaTime,
                'page': trackedPage
            }
        })

        trackMetric({ name: `ElapsedTime_${trackedPage}`, average: deltaTime})
    }

    trackPageView({
        name: String(to.name),
        uri: to.path,
        refUri: from.path,
        properties: {
            'user-initials': user.value?.username ?? ''
        }
    })
}
