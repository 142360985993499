import { sleep } from './async'

/**
 * Retries execution of a promise for [number] of times with a [delay] between each attempt.
 *
 * A typical use case is on renewal of expired token, and the app tries to refresh data at
 * the same time as the token is being renewed, which may, due to timings, cause the first
 * backend call to fail, but then subsequent retries will succeed.
 *
 * @param operation
 * @param delay
 * @param times
 * @returns
 */
export async function retryablePromise<T>(operation: () => Promise<T> | T, delay = 3000, times = 3): Promise<T> {
    try {
        return await operation()
    } 
    catch (ex) {
        if (times > 1) {
            await sleep(delay)
            return retryablePromise(operation, delay, times - 1)
        } else {
            throw ex
        }
    }
}

/**
 * Returns a promise instance in a deferred manner, meaning it is possible to explicitly resolve
 * or reject the promise from an external caller, while handing the promise itself to another part
 * of the code for it to await, whilst the previous code performs a task to resolve it.
 * 
 * @returns 
 */
export function useDeferredPromise<T>() {
    let resolve: (value: T | PromiseLike<T>) => void = null!
    let reject: (reason?: any) => void = null!

    const promise = new Promise<T>((resolveFn, rejectFn) => {
        resolve = resolveFn
        reject = rejectFn
    })

    return {
        deferred: {
            resolve: (value: T | PromiseLike<T>) => resolve(value),
            reject: (reason?: any) => reject(reason),
            promise,
            asReadyPromise: () => makeReadyPromise<T>(promise),
        },
    }
}

function makeReadyPromise<T>(promise: PromiseLike<T>): ReadyPromise<T> {
    return {
        ready: () => promise,
    }
}

export type ReadyPromise<T = void> = {
    ready: () => PromiseLike<T>
}

/**
 * Queues up promise operations to be performed sequentially, ensuring no race conditions can occur.
 * 
 * @returns 
 */
export function usePromiseQueue() {
    const queue: PromiseQueueElement[] = []
    let pendingPromise = false

    function enqueue<T = void>(asyncOperation: () => Promise<T>) {
        return new Promise<T>((resolve, reject) => {
            queue.push({
                asyncOperation,
                resolve,
                reject,
            })
            dequeue()
        })
    }

    function dequeue() {
        if (pendingPromise) {
            return false
        }

        const item = queue.shift()

        if (!item) {
            return false
        }

        try {
            pendingPromise = true
            item.asyncOperation()
                .then((value: any) => {
                    pendingPromise = false
                    item.resolve(value)
                    dequeue()
                })
                .catch((err: any) => {
                    pendingPromise = false
                    item.reject(err)
                    dequeue()
                })
        } 
        catch (err) {
            pendingPromise = false
            item.reject(err)
            dequeue()
        }

        return true
    }

    return {
        enqueue
    }
}

type PromiseQueueElement<T = any> = {
    asyncOperation: () => Promise<T>,
    resolve: (value: any) => void,
    reject: (err: any) => void
}
