import { subDays, subMinutes } from 'date-fns'
import { cloneDeep } from 'lodash'
import {
    Deviation,
    DeviationSummary,
    DeviationStatus,
    OrganizationUnit,
    OrganizationUnitType,
    DeviationSummaryPeriod,
} from '@dview/shared/models'
import { DeviationsService } from '../deviations-api'
import { fakeEndpointDelay } from './backend-mock-util'

export class DeviationsServiceMock implements DeviationsService {
    async getClosedDeviations(filters: OrganizationUnit[], period: DeviationSummaryPeriod = '24hours') {
        const deviations = await this.getOpenDeviations(filters) // just treat closed deviations as open for now
        return deviations
    }

    async getOpenDeviations(filters: OrganizationUnit[]): Promise<Deviation[]> {
        let deviationsData = cloneDeep(openDeviationsMockData)
        modifyOpenDeviations(deviationsData)

        if (filters.length) {
            deviationsData = pseudoDeviationsFiltering(deviationsData, filters)
        }

        await fakeEndpointDelay('slow')
        return Promise.resolve(deviationsData)
    }

    async getHocRatedDeviations(filters: OrganizationUnit[]): Promise<Deviation[]> {
        let deviationsData = cloneDeep(hocRatedDeviationsMockData)

        if (filters.length) {
            deviationsData = pseudoDeviationsFiltering(deviationsData, filters)
        }

        await fakeEndpointDelay('slow')
        return Promise.resolve(deviationsData)
    }

    async getNovoGlowLastUpdated() {
        const lastUpdated = new Date()

        if (lastUpdated.getHours() < 8) {
            lastUpdated.setDate(lastUpdated.getDate() - 1)
        }

        lastUpdated.setHours(8)
        lastUpdated.setMinutes(0)
        lastUpdated.setSeconds(0)

        await fakeEndpointDelay('instant')
        return Promise.resolve(lastUpdated)
    }

    async getDeviationSummary(period: DeviationSummaryPeriod) {
        let summary: DeviationSummary = null!

        if (period === '24hours') {
            summary = overview24Hours
        } else if (period === 'weekly') {
            summary = overviewWeekly
        } else if (period === 'monthly') {
            summary = overviewMonthly
        }

        await fakeEndpointDelay('fast')
        return Promise.resolve(summary)
    }
}

function pseudoDeviationsFiltering(data: Deviation[], filters: OrganizationUnit[]) {
    return data.filter(deviation => {
        return filters.some(filter => {
            let match = true
            if (filter.type === OrganizationUnitType.Svp) {
                match = match && deviation.svp1 === filter.name
            } else if (filter.type === OrganizationUnitType.Cvp) {
                match = match && deviation.cvp === filter.name
            } else if (filter.type === OrganizationUnitType.Vp) {
                //match = match && deviation. === filter.name
            } else if (filter.type === OrganizationUnitType.Department) {
                match = match && deviation.dept === filter.name
            } else if (filter.type === OrganizationUnitType.Team) {
                match = match && deviation.team === filter.name
            }

            if (filter.relations.svp) {
                match = match && filter.relations.svp === deviation.svp1
            }
            if (filter.relations.cvp) {
                match = match && filter.relations.cvp === deviation.cvp
            }
            if (filter.relations.vp) {
                //match = match && filter.relations.svp === deviation.svp1
            }
            if (filter.relations.department) {
                match = match && filter.relations.department === deviation.dept
            }

            return match
        })
    })
}

/**
 * Clone and copy mocked data several times in order to have a large enough dataset
 * to trigger the infinite scrolling mechanism.
 */
function enableInfiniteScroll(deviations: Deviation[]) {
    deviations = [...deviations, ...cloneDeep(deviations)]
    deviations = [...deviations, ...cloneDeep(deviations)]
    deviations = [...deviations, ...cloneDeep(deviations)]

    let counter = 1
    deviations.forEach(d => (d.caseId = 'caseid' + counter++))

    return deviations
}

////////////////////////////////////////////////////////////////////
//
//                DEVIATION OVERVIEW MOCK DATA
//
////////////////////////////////////////////////////////////////////
const overview24Hours: DeviationSummary = {
    openDeviations: {
        currentTotal: 127,
        periodTotal: 9,
        periodMajor: 3,
        periodMinor: 6,
        periodUnclassified: 0,
    },
    closedDeviations: {
        periodTotal: 2,
    },
    hocRatings: {
        periodRating0: 1,
        periodRating1: 0,
        periodRating2: 2,
        periodRating3: 3,
        periodRating4: 1,
        periodRating5: 1,
        periodRating6: 2,
    },
}

const overviewWeekly: DeviationSummary = {
    openDeviations: {
        currentTotal: 127,
        periodTotal: 45,
        periodMajor: 11,
        periodMinor: 24,
        periodUnclassified: 8,
    },
    closedDeviations: {
        periodTotal: 68,
    },
    hocRatings: {
        periodRating0: 11,
        periodRating1: 5,
        periodRating2: 4,
        periodRating3: 6,
        periodRating4: 7,
        periodRating5: 5,
        periodRating6: 3,
    },
}

const overviewMonthly: DeviationSummary = {
    openDeviations: {
        currentTotal: 127,
        periodTotal: 110,
        periodMajor: 31,
        periodMinor: 62,
        periodUnclassified: 17,
    },
    closedDeviations: {
        periodTotal: 83,
    },
    hocRatings: {
        periodRating0: 19,
        periodRating1: 9,
        periodRating2: 6,
        periodRating3: 10,
        periodRating4: 11,
        periodRating5: 7,
        periodRating6: 5,
    },
}

////////////////////////////////////////////////////////////////////
//
//                    DEVIATION MOCK DATA
//
////////////////////////////////////////////////////////////////////
const dateNow = new Date()
const openDeviationsMockData: Deviation[] = [
    {
        etqAppId: 74165,
        svp1: 'Biopharm (LoB)',
        svp2: 'Biopharm (LoB)',
        cvp: 'Bio API',
        dept: 'Department1',
        team: 'Team1',
        approvedDate: new Date('2020-11-21T00:00:00'),
        createdDate: new Date('2020-11-21T00:00:00'),
        startDate: new Date('2020-10-27T00:00:00'),
        discoveryDate: new Date('2020-11-21T00:00:00'),
        phase: 0,
        status: DeviationStatus.Open,
        title: 'Wrong result for particulate matter on certificate of Analysis',
        caseId: 'DV0073874',
        classification: 1,
        batchRelated: true,
        hocRating: 1,
        process: 'S2 Facilities and Environment : S2.03 Manage GxP regulated Facility : S2.03.01 Manage Gowning',
    },
    {
        etqAppId: 74169,
        svp1: 'Biopharm (LoB)',
        svp2: 'Biopharm (LoB)',
        cvp: 'Bio API',
        dept: 'Department1',
        team: 'Team2',
        approvedDate: new Date('2020-11-21T00:00:00'),
        createdDate: new Date('2020-11-20T00:00:00'),
        startDate: new Date('2020-10-27T00:00:00'),
        discoveryDate: new Date('2020-11-20T00:00:00'),
        phase: 0,
        status: DeviationStatus.Open,
        title:
            'Sweat drop leakage from goggles, and this exceedance is the cause for writing a very long deviation title, which will ultimately cause text to overflow when viewing on small mobile devices, and so users shall be annoyed <insert evil laughter here......>',
        caseId: 'DV0073878',
        classification: 0,
        batchRelated: false,
        hocRating: 2,
        process: 'S3 Other stuff : S3.01 Manage Unicorns and Rainbows : S2.03.01 Unicorn accessories',
    },
    {
        etqAppId: 74162,
        svp1: 'Biopharm QA',
        svp2: 'Biopharm QA',
        cvp: 'Bio FP GE',
        dept: 'Department4',
        team: 'Team9',
        approvedDate: new Date('2020-11-21T00:00:00'),
        createdDate: new Date('2020-11-20T00:00:00'),
        startDate: new Date('2020-10-27T00:00:00'),
        discoveryDate: new Date('2020-11-20T00:00:00'),
        phase: 0,
        status: DeviationStatus.Open,
        title: 'EM particle exceedence in grade in room Unicorn at site Marchmellow',
        caseId: 'DV0073871',
        classification: 0,
        batchRelated: true,
        hocRating: 0,
        process: 'S2 Facilities and Environment : S2.03 Manage GxP regulated Facility : S2.03.01 Manage Gowning',
    },
    {
        etqAppId: 74170,
        svp1: 'Biopharm (LoB)',
        svp2: 'Biopharm (LoB)',
        cvp: 'Bio API',
        dept: 'Department2',
        team: 'Team5',
        approvedDate: new Date('2020-11-21T00:00:00'),
        createdDate: new Date('2020-11-21T00:00:00'),
        startDate: new Date('2020-10-27T00:00:00'),
        discoveryDate: new Date('2020-11-20T00:00:00'),
        phase: 0,
        status: DeviationStatus.Open,
        title: 'EM particle exceedence in grade in room Bifrost at site Valhalla',
        caseId: 'DV0073879',
        classification: 2,
        batchRelated: false,
        hocRating: 5,
        process: 'S3 Other stuff : S3.01 Manage Unicorns and Rainbows : S2.03.01 Unicorn accessories',
    },
    {
        etqAppId: 74171,
        svp1: 'Biopharm (LoB)',
        svp2: 'Biopharm (LoB)',
        cvp: 'Bio API',
        dept: 'Department2',
        team: 'Team6',
        approvedDate: new Date('2020-11-21T00:00:00'),
        createdDate: new Date('2020-11-21T00:00:00'),
        startDate: new Date('2020-10-27T00:00:00'),
        discoveryDate: new Date('2020-11-20T00:00:00'),
        phase: 0,
        status: DeviationStatus.Open,
        title: 'Water leakage in pipe in room 568.A',
        caseId: 'DV0073880',
        classification: 0,
        batchRelated: false,
        hocRating: 3,
        process: 'S2 Facilities and Environment : S2.03 Manage GxP regulated Facility : S2.03.01 Manage Gowning',
    },
    {
        etqAppId: 74163,
        svp1: 'Biopharm QA',
        svp2: 'Biopharm QA',
        cvp: 'Bio FP GE',
        dept: 'Department4',
        team: 'Team9',
        approvedDate: new Date('2020-11-21T00:00:00'),
        createdDate: new Date('2020-11-20T00:00:00'),
        startDate: new Date('2020-10-27T00:00:00'),
        discoveryDate: new Date('2020-11-20T00:00:00'),
        phase: 0,
        status: DeviationStatus.Open,
        title: 'EM particle exceedence in grade in room Troll at site Marchmellow',
        caseId: 'DV0073872',
        classification: 2,
        batchRelated: false,
        hocRating: 4,
        process: 'S3 Other stuff : S3.01 Manage Unicorns and Rainbows : S2.03.01 Unicorn accessories',
    },
    {
        etqAppId: 74166,
        svp1: 'Biopharm QA',
        svp2: 'Biopharm QA',
        cvp: 'Bio FP GE',
        dept: 'Department4',
        team: 'Team9',
        approvedDate: new Date('2020-11-21T00:00:00'),
        createdDate: new Date('2020-11-21T00:00:00'),
        startDate: new Date('2020-10-27T00:00:00'),
        discoveryDate: new Date('2020-11-20T00:00:00'),
        phase: 0,
        status: DeviationStatus.Open,
        title: 'EM particle exceedence in grade in room Circus at site Marchmellow',
        caseId: 'DV0073875',
        classification: 0,
        batchRelated: true,
        hocRating: 5,
        process: 'S2 Facilities and Environment : S2.03 Manage GxP regulated Facility : S2.03.01 Manage Gowning',
    },
    {
        etqAppId: 74168,
        svp1: 'Biopharm QA',
        svp2: 'Biopharm QA',
        cvp: 'Bio FP GE',
        dept: 'Department4',
        team: 'Team9',
        approvedDate: new Date('2020-11-21T00:00:00'),
        createdDate: new Date('2020-11-21T00:00:00'),
        startDate: new Date('2020-10-27T00:00:00'),
        discoveryDate: new Date('2020-11-20T00:00:00'),
        phase: 0,
        status: DeviationStatus.Open,
        title: 'Manual mistake in gowning',
        caseId: 'DV0073877',
        classification: 1,
        batchRelated: false,
        hocRating: 5,
        process: 'S2 Facilities and Environment : S2.03 Manage GxP regulated Facility : S2.03.01 Manage Gowning',
    },
    {
        etqAppId: 74167,
        svp1: 'Biopharm (LoB)',
        svp2: 'Biopharm (LoB)',
        cvp: 'Bio API',
        dept: 'Department2',
        team: 'Team6',
        approvedDate: new Date('2020-11-21T00:00:00'),
        createdDate: new Date('2020-11-21T00:00:00'),
        startDate: new Date('2020-10-27T00:00:00'),
        discoveryDate: new Date('2020-11-20T00:00:00'),
        phase: 0,
        status: DeviationStatus.Open,
        title: 'DFP Blob TPU sendt til HUB',
        caseId: 'DV0073876',
        classification: 0,
        batchRelated: false,
        hocRating: 6,
        process: 'S2 Facilities and Environment : S2.03 Manage GxP regulated Facility : S2.03.01 Manage Gowning',
    },
    {
        etqAppId: 74164,
        svp1: 'Biopharm (LoB)',
        svp2: 'Biopharm (LoB)',
        cvp: 'Bio API',
        dept: 'Department2',
        team: 'Team6',
        approvedDate: new Date('2020-11-21T00:00:00'),
        createdDate: new Date('2020-11-20T00:00:00'),
        startDate: new Date('2020-10-27T00:00:00'),
        discoveryDate: new Date('2020-11-20T00:00:00'),
        phase: 0,
        status: DeviationStatus.Open,
        title: 'Water leakage under ground',
        caseId: 'DV0073873',
        classification: 2,
        batchRelated: false,
        hocRating: 1,
        process: 'S3 Other stuff : S3.01 Manage Unicorns and Rainbows : S2.03.01 Unicorn accessories',
    },
    {
        etqAppId: 74180,
        svp1: 'Biopharmaceuticals',
        svp2: 'Biopharmaceuticals',
        cvp: 'Bio Man',
        dept: 'Department7',
        team: 'Team13',
        approvedDate: new Date('2020-12-21T00:00:00'),
        createdDate: new Date('2020-12-14T00:00:00'),
        startDate: new Date('2020-10-27T00:00:00'),
        discoveryDate: new Date('2020-12-14T00:00:00'),
        phase: 0,
        status: DeviationStatus.Open,
        title: 'Wrong result for particulate matter on certificate of Analysis',
        caseId: 'DV0073881',
        classification: 0,
        batchRelated: true,
        hocRating: 6,
        process: 'S3 Other stuff : S3.01 Manage Unicorns and Rainbows : S2.03.01 Unicorn accessories',
    },
]

const hocRatedDeviationsMockData: Deviation[] = [
    {
        etqAppId: 74165,
        svp1: 'Biopharm (LoB)',
        svp2: 'Biopharm (LoB)',
        cvp: 'Bio API',
        dept: 'Department1',
        team: 'Team1',
        approvedDate: subMinutes(dateNow, 10),
        latestRatingDate: subMinutes(dateNow, 10),
        createdDate: subMinutes(dateNow, 10),
        startDate: subMinutes(dateNow, 10),
        discoveryDate: subMinutes(dateNow, 10),
        phase: 0,
        status: DeviationStatus.Closed,
        title: 'Some analysis with particles went haywire, all equipment has to be retrofitted with dark matter absorbers',
        caseId: 'DV0073874',
        classification: 1,
        batchRelated: true,
        hocRating: 1,
        process: 'S2 Facilities and Environment : S2.03 Manage GxP regulated Facility : S2.03.01 Manage Gowning',
    },
    {
        etqAppId: 74169,
        svp1: 'Biopharm (LoB)',
        svp2: 'Biopharm (LoB)',
        cvp: 'Bio API',
        dept: 'Department1',
        team: 'Team2',
        approvedDate: subMinutes(dateNow, 75),
        latestRatingDate: subMinutes(dateNow, 75),
        createdDate: subMinutes(dateNow, 75),
        startDate: subMinutes(dateNow, 75),
        discoveryDate: subMinutes(dateNow, 75),
        phase: 0,
        status: DeviationStatus.Closed,
        title: 'Dangerous sweat detected in lab area',
        caseId: 'DV0073878',
        classification: 0,
        batchRelated: false,
        hocRating: 3,
        process: 'S3 Other stuff : S3.01 Manage Unicorns and Rainbows : S2.03.01 Unicorn accessories',
    },
    {
        etqAppId: 74162,
        svp1: 'Biopharm QA',
        svp2: 'Biopharm QA',
        cvp: 'Bio FP GE',
        dept: 'Department4',
        team: 'Team9',
        approvedDate: subMinutes(dateNow, 350),
        latestRatingDate: subMinutes(dateNow, 350),
        createdDate: subMinutes(dateNow, 350),
        startDate: subMinutes(dateNow, 350),
        discoveryDate: subMinutes(dateNow, 350),
        phase: 0,
        status: DeviationStatus.Closed,
        title: 'Too many unicorns located simultaneously in research lab after midnight',
        caseId: 'DV0073871',
        classification: 0,
        batchRelated: true,
        hocRating: 2,
        process: 'S3 Other stuff : S3.01 Manage Unicorns and Rainbows : S2.03.01 Unicorn accessories',
    },
    {
        etqAppId: 74170,
        svp1: 'Biopharm (LoB)',
        svp2: 'Biopharm (LoB)',
        cvp: 'Bio API',
        dept: 'Department2',
        team: 'Team5',
        approvedDate: subDays(dateNow, 4),
        latestRatingDate: subDays(dateNow, 4),
        createdDate: subDays(dateNow, 4),
        startDate: subDays(dateNow, 4),
        discoveryDate: subDays(dateNow, 4),
        phase: 0,
        status: DeviationStatus.Closed,
        title: 'Air Purifier not enabled at Valhalla, causing very high PM exceedance',
        caseId: 'DV0073879',
        classification: 2,
        batchRelated: false,
        hocRating: 5,
        process: 'S2 Facilities and Environment : S2.03 Manage GxP regulated Facility : S2.03.01 Manage Gowning',
    },
    {
        etqAppId: 74171,
        svp1: 'Biopharm (LoB)',
        svp2: 'Biopharm (LoB)',
        cvp: 'Bio API',
        dept: 'Department2',
        team: 'Team6',
        approvedDate: subDays(dateNow, 5),
        latestRatingDate: subDays(dateNow, 5),
        createdDate: subDays(dateNow, 5),
        startDate: subDays(dateNow, 5),
        discoveryDate: subDays(dateNow, 7),
        phase: 0,
        status: DeviationStatus.Closed,
        title: 'Severe water leakage detected in room 1337.B, flooding most of basement',
        caseId: 'DV0073880',
        classification: 0,
        batchRelated: false,
        hocRating: 0,
        process: 'S2 Facilities and Environment : S2.03 Manage GxP regulated Facility : S2.03.01 Manage Gowning',
    },
    {
        etqAppId: 74163,
        svp1: 'Biopharm QA',
        svp2: 'Biopharm QA',
        cvp: 'Bio FP GE',
        dept: 'Department4',
        team: 'Team9',
        approvedDate: subDays(dateNow, 10),
        latestRatingDate: subDays(dateNow, 10),
        createdDate: subDays(dateNow, 10),
        startDate: subDays(dateNow, 10),
        discoveryDate: subDays(dateNow, 12),
        phase: 0,
        status: DeviationStatus.Closed,
        title: 'Gowning was not completed at satisfactory fashion, much more gowning required!',
        caseId: 'DV0073872',
        classification: 2,
        batchRelated: false,
        hocRating: 4,
        process: 'S2 Facilities and Environment : S2.03 Manage GxP regulated Facility : S2.03.01 Manage Gowning',
    },
    {
        etqAppId: 74180,
        svp1: 'Biopharmaceuticals',
        svp2: 'Biopharmaceuticals',
        cvp: 'Bio Man',
        dept: 'Department7',
        team: 'Team13',
        approvedDate: subDays(dateNow, 15),
        latestRatingDate: subDays(dateNow, 15),
        createdDate: subDays(dateNow, 15),
        startDate: subDays(dateNow, 15),
        discoveryDate: subDays(dateNow, 15),
        phase: 0,
        status: DeviationStatus.Closed,
        title: 'A black blob was sent to the wrong office room at location 471.A.Z41',
        caseId: 'DV0073881',
        classification: 0,
        batchRelated: true,
        hocRating: 6,
        process: 'S2 Facilities and Environment : S2.03 Manage GxP regulated Facility : S2.03.01 Manage Gowning',
    },
]

/* eslint-disable */
export function modifyOpenDeviations(deviations: Deviation[]) {
    const dateNow = new Date()
    deviations[0].createdDate = subMinutes(dateNow, 10)
    deviations[1].createdDate = subMinutes(dateNow, 75)

    deviations[2].createdDate = subDays(dateNow, 2)
    deviations[3].createdDate = subDays(dateNow, 4)
    deviations[4].createdDate = subDays(dateNow, 5)

    deviations[5].createdDate = subDays(dateNow, 10)
    deviations[6].createdDate = subDays(dateNow, 15)
    deviations[7].createdDate = subDays(dateNow, 22)
    deviations[8].createdDate = subDays(dateNow, 55)
    deviations[9].createdDate = subDays(dateNow, 121)
    deviations[10].createdDate = subDays(dateNow, 225)
}
/* eslint-enable */
